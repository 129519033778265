/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 28-sep-2016, 11:06:07
    Author     : keescornelisse1
*/

.node-type-ontdek-prins {
    .main-spanner .block-center {
        max-width: 100%;
    }
    
    .view-header-vacature {
        @include video_header($header_banner_height, $colors_grey_bg);
        
        .views-field-field-startafbeelding.video-under-it {
            opacity: 1;
        }
    }
    
    #block-system-main .field {
        width: 100%;
        
        &.field-type-youtube, &.field-name-field-vimeo-video  {
            .field-items {
                max-width: 100%;
                
                .youtube-container--responsive {
                    padding-bottom: 40%;
                }
                
                iframe, video {
                    height: $header_banner_height;
                    background-color: #000;
                    float: left;
                    width: 100%;
                }
            }
        }
        &.field-name-field-vimeo-video {
            overflow: hidden;
            width: 100%;
            height: $header_banner_height;
        }
        
        .field-items {
            max-width: $breakpoints_tablet;
            width: 100%;
            margin: 0 auto;
            position: relative;
        }
        
        &.field-name-field-headertekst {
            background-color: $colors_grey_bg;
            padding: 50px 0;
            color: $colors_white;
            
            h1 {
                color: $colors_white;
                margin-bottom: 40px;
                font-size: 36px;
                line-height: 44px;
            }
            p {
                color: $colors_base;
                font-weight: bold;
                font-size: 20px;
                line-height: 34px;
                padding: 0 15px;
                box-sizing: border-box;
            }
        }
        &.field-name-field-usp-s {
            background-color: $colors_grey_bg;
            padding: 20px 0 40px;
            overflow: hidden;
            
            & > .field-items > .field-item {
                width: 25%;
                float: left;
                text-align: center;
                color: $colors_white;
                
                img {
                    max-width: 150px;
                    height: auto;
                }

                .field-name-field-usp-titel {
                    font-weight: bold;
                }
                .field-name-field-usp-subtitel {
                    padding: 0 20px;
                    box-sizing: border-box;
                }
            }
            
        }
        &.field-name-body {
            @include body_style();
            box-sizing: border-box;
            
            & .field-item > *:nth-last-child(1) {
                padding-bottom: 40px;
                border-bottom: 1px solid #eeeeee;
            }
        }
    }
}

.footer {
    #block-views-meer-artikelen-over-block-1 {
        h2 {
            text-align: center;
            font-size: 30px;
            line-height: 38px;
            font-weight: normal;
            color: $color_text_grey;
        }
        .view-header {
            text-align: center;
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 40px;
            color: $color_text_grey;
            
            p {
                font-weight: 700;
            }
        }
        .content {
            max-width: $default_max_width;
            margin: 0 auto;
            width: 100%;
            position: relative;
        }

        width: 100%;
        float: left;
        margin: 80px 0;
    }
}