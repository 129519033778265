/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 16-sep-2016, 16:22:55
    Author     : keescornelisse1
*/
$product_max_width: 1000px;

.node-type-product {
    
    .header-interactive {
        width: 100%;
        height: $header_banner_height;
        position: relative;
        
        .view-header-producten {
            width: 100%;
            height: $header_banner_height;
            position: relative;

            .views-field-field-startafbeelding, .home-bg-image {
                width: 100%;
                height: $header_banner_height;
                max-height: calc(100vh - 145px);
                position: relative;
                background-color: $colors_grey_bg;
                transition: opacity 0.5s;
            }
            .views-field-name {
                @include backlink();
                padding-bottom: 20px;
            }
            .product-titel {
                position: absolute;
                z-index: 99;
                width: 100%;
                left: 0;
                
                .views-field {
                    margin: 0 auto;
                    color: #ffffff;
                    max-width: $product_max_width;
                    position: relative;
                    box-sizing: border-box;
                    padding-right: 26%;
                }
                h1 {
                    font-size: 45px;
                    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                }
                h3 {
                    font-size: 28px;
                    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                }
                .views-field-nothing {
                    padding-top: 30px;
                    
                    a {
                        margin-right: 10px;
                        background-color: $colors_grey_bg;
                        color: $colors_white;
                        border-radius: 8px;
                        padding: 8px 15px;
                        text-decoration: none;
                        font-size: 20px;
                    }
                }
            }
            .views-field-field-detailpagina-titel, 
            .views-field-title {
                text-align: left;
                font-size: 45px;
                font-weight: bold;
                line-height: 60px;
                text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
            }
            .views-field-field-subtitel {
                font-size: 24px;
                line-height: 34px;
                padding-top: 20px;
                text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
            }
            .views-field-field-product-afbeelding {
                position: absolute;
                bottom: -80px;
                right: 50%;
                margin-right: -600px;
                width: 600px;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .views-field-field-product-sub-afbeelding {
                position: absolute;
                bottom: -60px;
                right: 50%;
                margin-right: -490px;
                width: 100px;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    
    .main-spanner {
        background-color: $colors_grey_light;
        
        #main {
            max-width: 100%;
        }
        
        .field-name-body {
            max-width: $product_max_width;
            position: relative;
            margin: 80px auto 40px;
            
            h2 {
                color: $color_text_grey;
                font-size: 44px;
                line-height: 54px;
                margin-bottom: 20px;
            }
            p {
                font-size: 20px;
                line-height: 32px;
            }
        }
        
        .field-name-field-usp-s {
            width: 100%;
            margin: 50px 0 0;
            
            & > .field-items {
                text-align: center;
            }
            & > .field-items > .field-item {
                width: 33%;
                float: none;
                display: inline-block;
                text-align: center;
                color: $color_text_grey;
                vertical-align: top;
                
                .field-name-field-usp-afbeelding {
                    min-height: 123px;
                }
                .field-name-field-usp-afbeelding img {
                    width: 115px;
                    height: auto;
                }
                .field-name-field-usp-titel {
                    margin-top: 10px;
                    font-weight: bold;
                }
                .field-name-field-usp-subtitel {
                    padding: 0 50px;
                }
            }
            .field-collection-view {
                border-bottom: 0 none;
            }
        }
        .field-collection-container {
            border-bottom: 0 none;
            margin-bottom: 1em;
        }
        .group-subbox {
            width: 100%;
            float: left;
            background: $colors_green url('../images/samenstelling-bg.png') left center no-repeat;
            background-size: 50% 100%;
            margin-top: 40px;
            
            .field {
                width: 50%;
                float: left;
                clear: left;
                text-align: center;
                color: $colors_white;
                padding: 0 40px;
                box-sizing: border-box;
                
                &.field-name-field-usp-afbeelding {
                    img {
                        width: 150px;
                        height: auto;
                    }
                }
                &.field-name-field-subheader {
                    font-size: 38px;
                    line-height: 48px;
                    font-weight: bold;
                    
                    .field-item  {
                        max-width: 500px;
                        margin: 0 auto 20px;
                        position: relative;
                    }
                }
                &.field-name-field-subalinea {
                    font-size: 20px;
                    line-height: 30px;
                    
                    .field-item  {
                        max-width: 500px;
                        margin: 0 auto;
                        position: relative;
                    }
                    
                    p {
                        opacity: 0.6;
                        strong {
                            font-weight: normal;
                        }
                        margin-bottom: 10px;
                    }
                    p:nth-child(1){
                        opacity: 1;
                    }
                }
            }
            .field-name-field-subafbeelding {
                float: right;
                position: relative;
                padding: 0 0 0 0;
                
                &:before {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 35px 0 35px 24px;
                    border-color: transparent $colors_green;
                    display: block;
                    width: 0;
                    z-index: 1;
                    left: 0px;
                    top: 50%;
                    margin-top: -35px;
                }
                
                img {
                    width: 100%;
                    height: auto;
                    float: left;
                }
            }
        }
        .field-group-easy-responsive-tabs-nav-wrapper {
            
        }
        
        .node-product .content > .group-foodinfo > h2 {
            text-align: center;
            display: block;
            clip: auto;
            height: auto;
            position: static !important;
            overflow: visible;
            padding: 55px 0 35px;
            color: $color_text_grey;
            font-size: 34px;
            float: left;
            width: 100%;
            background-color: $colors_white;        
        }
        
        #group-foodinfo-node-product-default {
            float: left;
            width: 100%;
            background-color: $colors_grey_bg;
            
            //.resp-tabs-list {
                
                ul.resp-tabs-list {
                    background-color: $colors_white;
                    border-right: 0 none;
                    max-width: 100%;
                    padding: 20px auto 0;
                    display: block;
                    height: 43px;
                    text-align: center;
                    
                    li {
                        border-right: 0 none;
                        display: inline-block;
                        float: none;
                        background-color: $colors_white;
                        color: $colors_base;
                        font-size: 18px;
                        font-weight: normal;
                        padding: 8px 25px 6px !important;
                        border: 0 none;
                        margin-top: 1px;
                        
                        &.resp-tab-active {
                            background-color: $colors_grey_bg;
                            margin-bottom: 0 !important;
                            color: $colors_white;
                        }
                    }
                }
                .horizontal-tabs-panes {
                    width: 100%;
                    
                }
                .resp-tabs-container {
                    background-color: $colors_grey_bg;
                }
                .resp-tab-content {
                    background-color: $colors_grey_bg;
                    padding: 40px 0;
                    border: 0 none;
                    overflow: hidden;
                    max-width: $product_max_width;
                    width: 100%;
                    margin: 0 auto;
                    position: relative;
                    
                    .field {
                        max-width: $product_max_width;
                        width: 100%;
                        margin: 0 auto;
                        position: relative;
                        box-sizing: border-box;
                        padding: 0 15px;
                        color: $colors_white;
                        
                        .field-label {
                            margin-bottom: 10px;
                            color: $colors_white;
                        }
                        
                        p, ul, ol {
                            margin-bottom: 20px;
                            color: $colors_white;
                        }
                        li {
                            color: $colors_white;
                            margin-left: 10px;
                        }
                        
                        table {
                            border: 0 none;
                            color: $colors_white;
                            width: 100%;
                            
                            thead th {
                                padding-bottom: 5px;
                            }
                            td {
                                border: 0 none;
                                padding: 5px 10px;
                            }
                            th, tr.odd, tr.even {
                                border: 0 none;
                            }
                            
                            tr:nth-child(odd) td  {
                                background-color: $colors_grey_bg_light;
                            }
                            tr:nth-child(even),
                            tr:nth-child(even) td  {
                                background-color: transparent;
                            }
                        }
                    }
                    
                    &:nth-child(2) {
                        
                        
                        .field {
                            width: 33%;
                            float: left;
                            padding: 0 15px;
                            box-sizing: border-box;
                        }
                        .field-name-field-samenstelling {
                            width: 100%;
                            margin-top: 30px;
                        }
                    }
                //}
            }
        }
        
        .field-name-field-gerelateerde-artikelen {
            float: left;
            width: 100%;
            background-color: $colors_white;
            padding-bottom: 40px;
            
            h3 {
                text-align: center;
                display: block;
                clip: auto;
                height: auto;
                position: static !important;
                overflow: visible;
                margin: 55px 0 35px;
                color: $color_text_grey;
                font-size: 34px;
            }
            
            & > .field-items {
                max-width: $default_max_width;
                width: 100%;
                margin: 0 auto;
                position: relative;
                        
                & > .field-item {
                    width: 25%;
                    float: left;
                    
                    .node {
                        padding: 0 15px;
                        position: relative;
                        cursor: pointer;
                        
                        .field-name-field-startafbeelding, .field-name-field-startafbeelding .field-item {
                            width: 100%;
                            height: 300px;
                        }
                        .field-type-text {
                            position: absolute;
                            bottom: 30px;
                            left: 45px;
                            z-index: 3;
                            color: $colors_white;
                            line-height: 28px;
                            text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                            
                            a {
                                color: #ffffff;
                                text-decoration: none;
                            }

                            &.field-name-field-opgemaakte-titel-regel {

                                &-1 {
                                    font-size: 20px;
                                    font-weight: normal;
                                    bottom: 55px;
                                }
                                &-2 {
                                    font-size: 20px;
                                    font-weight: bold;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
    .second-main {
        background-color: $colors_white;
    }
    .second-main .block-center {
        max-width: 100%;
        
        .block {
            max-width: 100%;
        }
        
        h2 {
            text-align: center;
            display: block;
            clip: auto;
            height: auto;
            position: static !important;
            overflow: visible;
            padding: 55px 0 35px;
            color: $color_text_grey;
            font-size: 34px;
            float: left;
            width: 100%;
            background-color: $colors_white;   
        }
        .google-maps {
            width: 100%;
            float: left;
            position: relative;
            
            .google-store-locator-map {
                height: 500px;
                width: 100%;
            }
            .google-store-locator-panel {
                left: 50%;
                border: 0 none;
                margin-left: -190px;
                
                input {
                    padding: 15px 23px;
                    font-size: 22px;
                    -webkit-box-shadow: 0 2px 0px rgba(0, 0, 0, 0.06);
                    box-shadow: 0 2px 0px rgba(0, 0, 0, 0.06);
                }
                
                .uitgezoomd-melding {
                    display: none;
                }
            }
        }
    }
    #block-google-store-locator-google-store-locator-block {
        .store-list {
            display: none;
        }
        #cluster-loading {
            display: none;
        }
    } 
}