/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 21-sep-2016, 10:00:39
    Author     : keescornelisse1
*/

.node-type-leeftijdsfase {
    
    .main-spanner .block-center {
        max-width: 100%;
    }

    .view-header-leeftijdfasepagina {
        
        @include video_header($header_banner_height, $colors_grey_bg);
        
        .backlink {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: $colors_white;
            top: calc(30vh - 125px);
            left: 0;
            
            & > div {
                width: 100%;
                max-width: $default_max_width;
                margin: 0 auto;
                position: relative;
                text-align: left;
                padding-right: 45%;
                box-sizing: border-box;
            }            

            a {
                color: $colors_white;
                text-decoration: none;
                font-size: 20px;
                font-weight: bold;
                transition: opacity 0.3s;
                
                &:before {
                    content: " \2039 ";
                    font-size: 30px;
                    transition: all 0.3s;
                    margin-right: 5px;
                }
                &:hover {
                    opacity: 0.75;
                }
                &:hover:before {
                    padding-right: 5px;
                }
            }
        }
        
        .header-down-btn {
            @include header_down_btn();
        } 
        
        .views-field-field-headertekst {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: $colors_white;
            top: calc(35vh - 125px);
            left: 0;
            
            .field-content {
                width: 100%;
                max-width: $default_max_width;
                margin: 0 auto;
                position: relative;
                text-align: left;
                padding-right: 45%;
                box-sizing: border-box;
            }
            
            h1 {
                font-size: 52px;
                line-height: 64px;
                font-weight: bold;
                margin-bottom: 25px;
            }
            p {
                font-size: 24px;
                line-height: 36px;
                font-weight: normal;
            }
        }
    }
    
    .group-subbox {
        width: 100%;
        float: left;
        background-color: $colors_grey_bg;
        margin-top: 40px;

        .field {
            width: 50%;
            float: right;
            clear: right;
            text-align: center;
            color: $colors_white;
            padding: 0 40px;
            box-sizing: border-box;

            &.field-name-field-usp-afbeelding {
                img {
                    width: 120px;
                    height: auto;
                    margin: 40px 0 20px;
                }
            }
            &.field-name-field-subheader {
                font-size: 38px;
                line-height: 48px;
                font-weight: bold;

                .field-item  {
                    max-width: 500px;
                    margin: 0 auto 20px;
                    position: relative;
                }
            }
            &.field-name-field-subalinea {
                font-size: 20px;
                line-height: 30px;

                .field-item  {
                    max-width: 500px;
                    margin: 0 auto;
                    position: relative;
                }
                h3 {
                    font-size: 24px;
                    font-weight: normal;
                    margin-bottom: 10px;
                }
                h2 {
                    margin-bottom: 20px;
                }
                p {
                    opacity: 0.6;
                    strong {
                        font-weight: normal;
                    }
                    margin-bottom: 10px;
                }
                p:nth-child(1){
                    opacity: 1;
                }
            }
        }
        .field-name-field-subafbeelding {
            float: left;
            position: relative;
            padding: 0 0 0 0;

            &:before {
                content: '';
                position: absolute;
                border-style: solid;
                border-width: 35px 24px 35px 0;
                border-color: transparent $colors_grey_bg;
                display: block;
                width: 0;
                z-index: 1;
                right: 0px;
                top: 50%;
                margin-top: -35px;
            }

            img {
                width: 100%;
                height: auto;
                float: left;
            }
        }
    }
    
    #block-views-leeftijdsfase-tips-block {
        overflow: hidden;
        background-color: $colors_grey_light;
    }
    
    .view-leeftijdsfase-tips {
        max-width: $default_max_width;
        width: $default_width;
        margin: 75px auto;
        position: relative;
        overflow: hidden;
        
        ul li {
            list-style: none;
        }
        
        .views-field-field-tips-1,
        .views-field-field-tips-titel {
            width: 35%;
            float: left;
            clear: left;
            box-sizing: border-box;
        }
        .field-name-field-usp-subtitel {
            padding-right: 30px;
            box-sizing: border-box;
        }
        
        .views-field-field-tips-titel {
            min-height: 130px;
            
            h3 {
                font-size: 40px;
                line-height: 48px;
                padding-right: 60px;
                box-sizing: border-box;
                color: $color_text_grey;
            }
        }
        .views-field-field-tips-1 {
            padding-right: 60px;
            
            li {
                margin: 0 0 0 0;
                border-bottom: 1px solid $colors_grey_light;
                font-size: 22px;
                line-height: 60px;
                transition: all 0.3s;
                
                &:hover, &.selected {
                    color: $colors_red;
                    font-weight: bold;
                    cursor: pointer;
                }
                &:hover {
                    opacity: 0.8;
                }
                
                &.last {
                    border-bottom: 0 none;
                }
            }
        }
        .views-field-field-tips {
            width: 65%;
            float: right;
            margin-top: -130px;
            
            ul {
                max-height: 475px;
                width: 100%;
                overflow:hidden;
            }
            
            li {
                
                .field-name-field-quote-afbeelding  {
                    img {
                        width: 100%;
                        height: auto;
                    }
                    
                    margin-bottom: 20px;
                }
                a {
                    color: $colors_red;
                }
            }
            
        }
    }
    .view-leeftijdsfase-verzorging {
        width: 100%;
        float: left;
        background: $colors_green url('../images/leeftijdsfase-verzorging.jpg') center center no-repeat;
        background-size: cover;
        
        .view-content {
            max-width: $default_max_width;
            width: $default_width;
            margin: 75px auto;
            position: relative;
            overflow: hidden;
            color: $colors_white;
        }
        .views-field-field-verzorging-tekst {
            margin-bottom: 40px;
            
            h2 {
                font-size: 44px;
                margin-bottom: 30px;
            }
            p {
                font-size: 24px;
                line-height: 32px;
            }
        }
        .views-field-field-usp-s, .views-field-field-verzorging-items {
            ul {
                text-align: center;
            }
            li {
                list-style: none;
                display: inline-block;
                padding: 0 40px;
                max-width: 250px;
                width: 33%;
                
                &.first {
                    padding-left: 0;
                }
                &.last {
                    padding-right: 0;
                }
                
                img {
                    border-radius: 125px;
                    box-sizing: border-box;
                    -moz-box-shadow: inset 0 0 0 10px $colors_white;
                    box-shadow: inset 0 0 0 10px $colors_white;
                    border: 4px solid $colors_white;
                }
                .field-name-field-usp-subtitel {
                    font-size: 24px;
                    line-height: 32px;
                    box-sizing: border-box;
                    width: 100%;
                }
                .field-name-field-usp-link {
                    display: none;
                }
            }
        }
    }
    #block-views-leeftijdsfase-eigenaren-block {
        overflow: hidden;
    }
    .view-leeftijdsfase-eigenaren {
        margin: 40px 0;
        
        .view-header {
            color: $color_text_grey;
            
            h4 {
                text-transform: uppercase;
                font-size: 20px;
                margin-bottom: 20px;
            }
            h2 {
                font-size: 44px;
                margin-bottom: 40px;
            }
        }
        .view-content {
            margin-left: -15px;
            margin-right: -15px;
            overflow: hidden;
            
            .views-row {
                width: 50%;
                float: left;
                padding: 0 15px;
                box-sizing: border-box;
                position: relative;
                
                &:hover .views-field-field-youtube-video .field-content:after {
                    opacity: 0.8;
                }
                
                .views-field-field-youtube-video {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    padding: 0 15px;
                    box-sizing: border-box;
                    cursor: pointer;
                    
                    .field-content {
                        background-color: $colors_grey_bg;
                        overflow: hidden;
                        position: relative;
                        
                        &:after {
                            content: "";
                            height: 100%;
                            width: 100%;
                            background-color: #000;
                            opacity: 0.5;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: opacity 0.3s;
                        }
                    }
                    
                    img {
                        width: 100%;
                        height: auto;
                        float: left;
                        
                    }
                }
                .views-field-field-usp-titel {
                    position: relative;
                    z-index: 5;
                    text-align: center;
                    width: 100%;
                    margin-top: 10%;
                    color: $colors_white;
                    font-size: 26px;
                    
                    h3 {
                        
                        &:before {
                            content: "";
                            width: 76px;
                            height: 99px;
                            background: transparent url('../images/icon-microphone.png') center center no-repeat;
                            background-size: cover;
                            display: block;
                            margin: 0 auto 20px;
                        }
                        
                    }
                }
                .views-field-field-usp-subtitel {
                    position: relative;
                    z-index: 5;
                    text-align: center;
                    width: 100%;
                    margin-top: 30px;
                    color: $colors_white;
                    box-sizing: border-box;
                    padding: 0 20%;
                }
                .views-field-field-youtube-video-1 {
                    position: relative;
                    z-index: 5;
                    text-align: center;
                    width: 100%;
                    margin: 10px 0 50px;
                    color: $colors_white;
                    box-sizing: border-box;
                    padding: 0 20%;
                    
                    a {
                        text-decoration: none;
                        outline:0;
                        
                        &:focus {
                            outline:0;
                        }
                        
                        &:before {
                            content: "Speel video af ";
                            color: $colors_green;
                            opacity: 1;
                            padding-right: 30px;
                            background: transparent url('../images/icon-play.png') center right no-repeat;
                            background-size: 24px 24px;
                            height: 24px;
                            line-height: 24px;
                            display: inline-block;
                        }
                    }
                    
                    img {
                        opacity: 0;
                        display: none;
                    }
                }
            }
        }
    }
    
    .leeftijdsfase-submenu {
        width: 100%;
        
        ul {
            list-style: none;
            text-align: center;
            width: 100%;
            
            li {
                display: inline-block;
                padding: 0 20px;
                
                a {
                    color: $colors_base;
                    text-transform: uppercase;
                    height: 50px;
                    line-height: 50px;
                    text-decoration: none;
                    transition: all 0.3s;
                    
                    &:hover {
                        color: $color_text_grey;
                    }
                }
            }
        }
    }
    
}

#colorbox {
    background-color: $colors_green !important;
    background-image: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    
    #cboxCurrent {
        color: $colors_white;
        border-left: 0 none;
        bottom: -31px;
    }
}