/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 28-sep-2016, 11:06:07
    Author     : keescornelisse1
*/

.node-type-vacature {
    
    .view-header-vacature {
        @include video_header($header_banner_height, $colors_grey_bg);
    }
    
    .main-spanner .block-center {
        max-width: 100%;
    }
    #block-system-main .field {
        width: 100%;
        
        .field-items {
            max-width: $breakpoints_tablet;
            width: 100%;
            margin: 0 auto;
            position: relative;
        }
        
        &.field-name-field-headertekst {
            background-color: $colors_grey_bg;
            padding: 50px 0;
            color: $colors_white;
            
            h1 {
                color: $colors_white;
                margin-bottom: 40px;
                font-size: 36px;
                line-height: 44px;
            }
            p {
                color: $colors_base;
                font-weight: bold;
                font-size: 20px;
                line-height: 34px;
                padding: 0 15px;
                box-sizing: border-box;
            }
        }
        &.field-name-body {
            box-sizing: border-box;
            @include body_style();
            margin-bottom: 40px;
        }
    }
    .second-main {
        .block {
            max-width: 100%;
            
        }
    }
    #block-views-alle-vacatures-block {
        background-color: $colors_green;
        color: $colors_white;
        padding: 40px 0; 
        overflow: hidden;
        
        h2, .view-content {
            max-width: 980px;
            width: $default_width;
            margin: 0 auto;
            position: relative;
        }
        h2 {
            margin-bottom: 30px;
            font-size: 34px;
        }
        .view-content {
            border-top: 2px solid rgba(255,255,255, 0.5);
        }
        .views-row {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            border-bottom: 2px solid rgba(255,255,255, 0.5);
            cursor: pointer;
            
            .views-field-title {
                float: left;
                font-weight: bold;
            }
            .views-field-view-node {
                float: right;
                font-size: 16px;
                a {
                    transition: all 0.3s;
                }
            }
            
            a {
                color: $colors_white;
                text-decoration: none;
            }
            &:hover {
                .views-field-view-node {
                    line-height: 37px;
                    
                    a {
                        @include button_red();
                        height: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}