$footer_menu_height: 70px;

.footer {
    .newsletter-signup {
        margin-top: 30px;
    }
}
.second-main {
    .newsletter-signup {
        width: 100% !important;
        max-width: 100% !important;
        float: left;
    }
}
.newsletter-signup {
    width: 100%;
    background: #98bf0e url('../images/block-newsletter.png') center center no-repeat;
    background-size: cover;
    float: left;
    
    .block-center {
        max-width: 450px;
    }
    
    .content {
        padding: 70px 0;
        
        p {
            font-size: 24px;
            line-height: 33px;
            color: #ffffff;
            font-weight: bold;
        }
        
        form {
            margin-top: 40px;
            
            input {
                height: 50px;
                line-height: 50px;
                box-sizing: border-box;
                border: 0 none;
                border-radius: 10px;
                font-family: 'Open Sans', sans-serif;
                font-size: $default_font_size;
                padding: 0 20px;
                outline:none;
                
                &[name="emailaddress"] {
                    width: 300px;
                    background-color: $color_green_input;
                    color: $colors_white;
                    margin-right: 10px;
                    -moz-appearance:    textfield;
                    -webkit-appearance: textfield;
                    appearance: textfield;
                }
                
                &[type="submit"] {
                    display: inline-block;
                    background-color: #ffffff;
                    color: $colors_green;
                    font-weight: bold;
                    cursor: pointer;
                    transition: all 0.3s;
                    
                    &:hover {
                        opacity: 0.7;
                    }
                }
                
                @include placeholder(#ffffff); 
            }
        }
    }
}

.copyright {
    min-height: 260px;
    padding: 20px 0 0 0;
    
    #logo-footer {
        float: left;
        padding-right: 40px;
        width: 15%;
        box-sizing: border-box;
        
        img {
            height: $footer_menu_height;
        }
    }
    
    .block-locale {
        margin-top: 17px;
        margin-right: 50px;
    }
    
    .region-copyright {
        width: 85%;
    }
    .region-copyright, 
    .block {
        float: left;
        
        .menu {
            height: $footer_menu_height;
            
            li {
                list-style: none;
                float: left;
                height: $footer_menu_height;
                line-height: $footer_menu_height;
                padding: 0;
                margin: 0 20px 0 0;
                
                a {
                    color: $colors_base;
                    text-decoration: none;
                    font-weight: bold;
                    padding: 0 10px;
                    font-size: 16px;
                    transition: color 0.5s;

                    &:hover,
                    &:active {
                        color: $colors_red;
                    }
                }
            }
        }
    }
    
    .social-icons {
        padding-top: 15px;
        float: right;
        
        ul li {
            list-style: none;
            display: inline-block;
            
            a {
                text-indent: -10000px;
                
                &.icon {
                    width: 44px;
                    height: 44px;
                    display: block;
                    margin-left: 5px;
                    
                    @include icon('facebook');
                    @include icon('linkedin');
                    @include icon('twitter');
                    @include icon('instagram');
                    @include icon('youtube');
                }
            }
        }
        
    }
}