/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 7-sep-2016, 8:55:04
    Author     : keescornelisse1
*/
.productlijn .main-spanner #main,
.productlijn .page-spanner .region-block .block-center {
    max-width: 100%;
}

/*.view-productlijn-producten .view-filters {
    display: none;
}
.logged-in .view-productlijn-producten .view-filters {
    display: block;
}*/

.page-taxonomy-term {
    
    .view-category-header {
        
        @include video_header($header_banner_height, $colors_grey_bg);
        
        .views-field-field-startafbeelding {
            display: none;
            
            .home-bg-image {
                opacity: 0;
            }
        }
        
        .views-field-description-1 {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: #ffffff;
            top: 20vh;
            left: 0;
            text-align: center;
            
            .field-content {
                max-width: 730px;
                margin: 0 auto;
                
                h4 {
                    font-size: 48px;
                    line-height: 52px;
                    font-weight: normal;
                }
                h1 {
                    font-size: 60px;
                    line-height: 72px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }
                p {
                    font-size: 24px;
                    line-height: 32px;
                }
                a {
                    margin-right: 10px;
                    background-color: $colors_grey_bg;
                    color: $colors_white;
                    border-radius: 8px;
                    padding: 8px 15px;
                    text-decoration: none;
                    font-size: 20px;
                    margin-top: 30px;
                    display: inline-block;

                    &.product-list {
                        background-image: url(../images/grid-small.png);
                        background-repeat: no-repeat;
                        padding-left: 34px!important;
                        background-size: 15px 15px;
                        background-position: 9px center;
                    }
                }
            }
        }
        .views-field-nothing {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: #ffffff;
            top: 40vh;
            left: 0;
            text-align: center;

            a {
                margin-right: 10px;
                background-color: $colors_grey_bg;
                color: $colors_white;
                border-radius: 8px;
                padding: 8px 15px;
                text-decoration: none;
                font-size: 20px;
                
                &.product-list {
                    background-image: url(../images/grid-small.png);
                    background-repeat: no-repeat;
                    padding-left: 34px!important;
                    background-size: 15px 15px;
                    background-position: 9px center;
                }
            }
        }
        
    }
    
    .group-introlijn,
    .field-name-field-voordelen,
    .field-name-field-opsomming-voordelen {
        max-width: $default_max_width;
        width: $default_width; 
        margin: 0 auto;
        position: relative;
        overflow: hidden;
    }
    
    .vocabulary-product-category {
        padding: 80px 0;
        
        .field-name-field-product-afbeelding {
            width: 50%;
            float: left;
            
            img {
                width: 100%;
                max-width: 600px;
            }
        }
        .field-name-field-productlijnomschrijving {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 0 20px;
            color: $color_text_grey;
            
            h2 {
                margin: 40px 0 40px;
                font-size: 44px;
            }
            p {
                font-size: 1.3vw;
                line-height: 2.5vw;
            }
        }
        .group-samenstelling {
            width: 100%;
            float: left;
            background: $colors_green url('../images/samenstelling-bg.png') left center no-repeat;
            background-size: 50% 100%;
            margin-top: 40px;
            
            .field-name-field-samenstellingomschrijving,
            .field-name-field-samenstelling-afbeelding {
                width: 50%;
                float: left;
                box-sizing: border-box;
            }
            .field-name-field-samenstellingomschrijving {
                padding: 80px 40px 0px;
                color: $colors_white;
                
                .field-label {
                    font-size: 38px;
                    font-weight: normal;
                    margin-bottom: 25px;
                    width: 100%; 
                }
                
                font-size: 1.3vw;
                line-height: 2.5vw;
                
            }
            .field-name-field-samenstelling-afbeelding {
                position: relative;
                
                &:before {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 35px 0 35px 24px;
                    border-color: transparent $colors_green;
                    display: block;
                    width: 0;
                    z-index: 1;
                    left: 0px;
                    top: 50%;
                    margin-top: -35px;
                }
                
                img {
                    width: 100%;
                    height: auto;
                    float: left;
                }
            }
        }
        
        .field-name-field-voordelen {
            text-align: center;
            padding-top: 60px;
            color: $color_text_grey;
            
            h2 {
                font-size: 34px;
                margin-bottom: 20px;
                line-height: 40px;
            }
            p {
                font-size: 1.3vw;
                color: $colors_base;
                line-height: 2.0vw;
            }
        }
        .field-name-field-opsomming-voordelen {
            margin: 40px auto 80px auto;
            
            
            .field-items {
                padding: 0 40px;
            }
            .field-item {
                width: 48%;
                float: left;
                min-height: 65px;
                line-height: 30px;
                border-bottom: 1px solid $colors_grey_light;
                color: $color_text_grey;
                box-sizing: border-box;
                padding: 17px 0 17px 65px;
                @include in_icon('../images/samenstelling-list-item.png', transparent);
                background-size: 38px 38px;
                background-position: left center;
                
                &.odd {
                    float: right;
                }
            }
        }
    }
    #block-views-productlijn-wanneer-block {
        max-width: 100%;
        background-color: $colors_grey_bg;
        overflow: hidden;
        
        .content {
            max-width: $default_max_width;
            width: 100%;
            position: relative;
            margin: 0 auto;
        }
        
        .views-row {
            float: left;
            width: 100%;
            margin: 50px 0;
            color: $colors_white;
            
            h2 {
                font-size: 34px; 
                line-height: 38px;
                margin-bottom: 50px;
            }
            
            .views-field-field-usp-s li {
                width: 33.333%;
                padding: 0 20px;
                text-align: center;
                box-sizing: border-box;
                float: left;
                list-style: none;
                margin: 0;
                
                img {
                    width: 64px;
                    height: auto;
                }
                .field-name-field-usp-subtitel {
                    max-width: 260px;
                    position: relative;
                    margin: 15px auto;
                }
            }
        }
    }
    
    
    #block-views-productlijn-overzicht-block {    
        max-width: $default_max_width;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }

    .view-productlijn-overzicht {
        padding: 80px 0;
        float: left;
        width: 100%;
        
        
        .views-row {
            width: 50%;
            float: left;
            position: relative;
            box-sizing: border-box;
            
            &.views-row-odd {
                padding-right: 25px;
                clear: left;
                margin-bottom: 75px;
            }
            &.views-row-even {
                padding-left: 35px;
            }
            
            
            h3 {
                width: 60%;
                float: left;
                margin-bottom: 10px;
                font-size: 22px;
                
                a {
                    color: $color_text_grey;
                    text-decoration: none;
                }
            }
            .views-field-nothing {
                width: 60%;
                float: left;
                
                p {
                    margin-bottom: 10px;
                    font-size: 17px;
                }
                a {
                    @include button_green();
                    
                    /*color: $color_text_grey;
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 17px;
                    
                    &:after {
                        content: " \203A ";
                        transition: all 0.3s;
                    }
                    &:hover:after {
                        padding-left: 5px;
                    }*/
                }
            }
            .views-field-field-product-afbeelding {
                width: 35%;
                position: relative;
                float: right;
                margin-top: -5%;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    
    .view-productlijn-producten {
        padding: 80px 0 40px;
        float: left;
        width: 100%;
        overflow: hidden;
        
        .view-header {
            font-size: 38px;
            line-height: 46px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            color: $color_text_grey;
        }
        
        .view-filters {
            margin-bottom: 20px;
            
            .views-exposed-widget {
                width: 100%;
                label {
                    display: none;
                }
            }
            .form-item {
                display: block;
                text-align: center;
                
                .form-type-bef-link {
                    display: inline-block;
                    
                    
                    & > a {
                        @include button_green();
                        opacity: 0.7;
                        
                        &.active {
                            opacity: 1;
                        }
                    }
                } 
            }
        }
        .view-content {
            margin-left: -15px;
            margin-right: -15px;
            
            .views-row {
                width: 25%;
                float: left;
                padding: 0 15px;
                box-sizing: border-box;
                text-align: center;
                margin-bottom: 40px;
                min-height: 410px;
                
                .views-field-field-product-afbeelding {
                    img {
                        max-height: 225px;
                        width: auto;
                    }
                }
                .views-field-name {
                    color: $color_text_grey;
                }
                .views-field-field-detailpagina-titel a, 
                .views-field-title a {
                    color: $color_text_grey;
                    font-weight: bold;
                    font-size: 1.2em;
                    text-decoration: none;
                }
            }
        }
    }
    
    $sfeer_footer_height: 350px;
    #block-views-productlijn-sfeer-block-1 {
        max-width: $default_width;
        background-color: $colors_green;
        float: left;
        width: 100%;
        
        .views-field {
            float: left;
            box-sizing: border-box;
            color: $colors_white;
        }
        .views-field-field-quote {
            width: 35%;
            text-align: center;
            display: flex;
            justify-content: center;
            height: $sfeer_footer_height;
            
            .field-content {
                align-self: center;
                font-family: 'Sniglet', sans-serif;
                font-size: 60px;
                line-height: 60px;
                width: 75%;
                min-width: 280px;
            }
        }
        .views-field-field-quote-afbeelding {
            width: 65%;
        }
    }
    
    .view-productlijn-sfeer {
        width: 100%;
        float: left;
        height: $sfeer_footer_height;
        
        .footer-bg-image {
            width: 100%;
            height: $sfeer_footer_height;
            background-position: center center;
        }
    }
}

.page-taxonomy-term.page-taxonomy-term-301 .view-productlijn-producten .view-filters .form-item .form-type-bef-link > a.active {
    display: none;
}