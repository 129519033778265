.node-type-expert {
    .region-block .block-center {
        width: 100%;
        max-width: 100%;
    }
    .expert-wrapper {
        padding: 40px 0;
        background-color: #eeeeee;
        overflow: hidden;

        .expert-wrapper-inner {
            max-width: 1000px;
            margin: 0 auto;
        }
    }

    .ds-2col-stacked {
      .group-left {
          width: 35%;
          float: left;
      }
      .group-right {
        width: 60%;
        float: left;
        padding-left: 40px;
      }

      @media all and (max-width: 768px) {
        .group-left, .group-right {
          width: 100%;
          padding: 0 20px 20px;
          box-sizing: border-box;

          .field-name-body {
            padding-left: 0;
          }
        }
      }
    }

    .field-name-field-startafbeelding {
        .field-item {
            width: 100%;
            height: 450px;
            background-size: contain;
            background-repeat: no-repeat;
            max-height: 100vw;
        }
    }

    .field-name-teruglink-experts, 
    .field-name-backlink-experts {
      @include backlink();
      margin-bottom: 10px;

      a {
        color: #343434;
      }
    }


    .field-name-title {
        h2 {
            color: #343434;
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 0px;
        }
    }
    .field-name-field-functie {
      font-style: italic;
    }

    .field-name-body {
        @include body_style();
    }

    .field-name-field-gerelateerde-artikelen {
      float: left;
      width: 100%;
      background-color: $colors_white;
      padding-bottom: 40px;

      h3 {
        text-align: center;
        display: block;
        clip: auto;
        height: auto;
        position: static !important;
        overflow: visible;
        margin: 55px 0 35px;
        color: $color_text_grey;
        font-size: 24px;
      }

      &>.field-items {
        max-width: $default_max_width;
        width: 100%;
        margin: 0 auto;
        position: relative;

        &>.field-item {
          width: 25%;
          float: left;

          ul.links {
              display: none;
          }

          .node {
            padding: 0 15px;
            position: relative;
            cursor: pointer;

            .field-name-field-startafbeelding,
            .field-name-field-startafbeelding .field-item {
              width: 100%;
              height: 300px;
            }

            .field-type-text {
              position: absolute;
              bottom: 30px;
              left: 45px;
              z-index: 3;
              color: $colors_white;
              line-height: 28px;
              text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);

              a {
                color: #ffffff;
                text-decoration: none;
              }

              &.field-name-field-opgemaakte-titel-regel {

                &-1 {
                  font-size: 20px;
                  font-weight: normal;
                  bottom: 55px;
                  display: none;
                }

                &-2 {
                  font-size: 20px;
                  font-weight: bold;
                }

              }
            }
          }
        }
      }
    }

}

.page-node-9204,
.page-node-9219 {
    #main {
      max-width: 100%;
    }
}

.node-overzicht {
  .field-name-body {
    padding: 40px 0;
    background-color: #25303d;
    text-align: center;

    h1, h2, h3, p {
      color: #fff;
    }
    h1 {
      color: #ffffff;
      margin-bottom: 40px;
      font-size: 36px;
      line-height: 44px;
    }
    p {
      color: #d0d2d4;
      font-weight: 600;
      font-size: 20px;
      line-height: 34px;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
  .field-name-field-headertekst {
    @include body_style();
  }
}

.view-experts {
  max-width: $default_max_width;
  width: 100%;
  margin: 40px auto 40px;
  overflow: hidden;
  position: relative;

  .views-row {
    width: 25%;
    float: left;
    margin-bottom: 10px;

    @media all and (max-width: 1024px) {
      width: 33.3333%;
    }
    @media all and (max-width: 768px) {
      width: 50%;
    }
    @media all and (max-width: 500px) {
      width: 100%;
    }
    
    .node {
      padding: 0 5px;
      position: relative;
      cursor: pointer;

      .field-name-field-startafbeelding,
      .field-name-field-startafbeelding .field-item {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .field-name-title {
        position: absolute;
        bottom: 50px;
        left: 45px;
        z-index: 3;
        color: $colors_white;
        line-height: 28px;
        text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);

        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
      .field-name-field-functie {
        position: absolute;
        bottom: 20px;
        left: 45px;
        z-index: 3;
        color: $colors_white;
        line-height: 28px;
        text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
        font-style: italic;

        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}


