.node-type-werkenbij {
    .main-spanner .block-center {
        max-width: 100%;
    }

    .view-header-vacature {
        @include video_header($header_banner_height, $colors_grey_bg);

        .views-field-field-startafbeelding.video-under-it {
        opacity: 1;
        }
    }

    .view-header-video {

      @include video_header($header_banner_height, $colors_grey_bg);

      .views-field-field-startafbeelding {
        display: none;

        .home-bg-image {
          opacity: 0;
        }
      }

      .views-field-field-headertekst {
        position: absolute;
        z-index: 99;
        width: 100%;
        color: #ffffff;
        top: 20vh;
        left: 0;
        text-align: center;

        .field-content {
          max-width: 730px;
          margin: 0 auto;

          h4 {
            font-size: 48px;
            line-height: 52px;
            font-weight: normal;
          }

          h1 {
            font-size: 60px;
            line-height: 72px;
            font-weight: bold;
            margin-bottom: 15px;
          }

          p {
            font-size: 24px;
            line-height: 32px;
          }

          a {
            margin-right: 10px;
            background-color: $colors_grey_bg;
            color: $colors_white;
            border-radius: 8px;
            padding: 8px 15px;
            text-decoration: none;
            font-size: 20px;
            margin-top: 30px;
            display: inline-block;

            &.product-list {
              background-image: url(../images/grid-small.png);
              background-repeat: no-repeat;
              padding-left: 34px !important;
              background-size: 15px 15px;
              background-position: 9px center;
            }
          }
        }
      }

    }

    // .view-header-vacature {
    //   @include video_header($header_banner_height, $colors_grey_bg);

    //   .views-field-field-startafbeelding.video-under-it {
    //     opacity: 1;
    //   }
    // }

    #block-system-main .field {
      width: 100%;

      &.field-name-field-link {
        background-color: #eeeeee;
        padding: 20px 0;
        text-align: center;

        a {
          @include button_green();

          padding: 8px 30px;
          font-size: 20px;
          font-weight: bold;
        }
      }

      &.field-name-field-headertekst {
        background-color: $colors_grey_bg;
        padding: 50px 0;
        color: $colors_white;

        h1 {
          color: $colors_white;
          margin-bottom: 40px;
          font-size: 36px;
          line-height: 44px;
        }

        p {
          color: $colors_base;
          font-weight: bold;
          font-size: 20px;
          line-height: 34px;
          padding: 0 15px;
          box-sizing: border-box;
        }
      }
      
      &.field-type-youtube,
      &.field-name-field-vimeo-video {
        .field-items {
          max-width: 100%;

          .youtube-container--responsive {
            padding-bottom: 40%;
          }

          iframe,
          video {
            height: $header_banner_height;
            background-color: #000;
            float: left;
            width: 100%;
          }
        }
      }

      &.field-name-field-vimeo-video {
        overflow: hidden;
        width: 100%;
        height: $header_banner_height;
      }

      .field-items {
        max-width: $breakpoints_tablet;
        width: 100%;
        margin: 0 auto;
        position: relative;
      }

      &.field-name-field-headertekst {
        background-color: $colors_grey_bg;
        padding: 50px 0;
        color: $colors_white;

        h1 {
          color: $colors_white;
          margin-bottom: 40px;
          font-size: 36px;
          line-height: 44px;
        }

        p {
          color: $colors_base;
          font-weight: bold;
          font-size: 20px;
          line-height: 34px;
          padding: 0 15px;
          box-sizing: border-box;
        }
      }
      &.field-name-field-usp-s {
        background-color: $colors_grey_bg;
        padding: 20px 0 40px;
        overflow: hidden;

        &>.field-items>.field-item {
          width: 25%;
          float: left;
          text-align: center;
          color: $colors_white;

          img {
            max-width: 150px;
            height: auto;
          }

          .field-name-field-usp-titel {
            font-weight: bold;
          }

          .field-name-field-usp-subtitel {
            padding: 0 20px;
            box-sizing: border-box;
          }
        }

      }

      &.field-name-body {
        @include body_style();
        box-sizing: border-box;

        & .field-item>*:nth-last-child(1) {
          padding-bottom: 40px;
          border-bottom: 1px solid #eeeeee;
        }
      }
    }

    .main-content .block-views {
        h2 {
            max-width: $breakpoints_tablet;
            width: 100%;
            margin: 20px auto 20px;
            position: relative;
        }
    }

    .view-alle-vacatures,
    .view-vacature-test,
    .view-alle-nieuws {

        .view-content {
            max-width: $breakpoints_tablet;
            width: 100%;
            margin: 20px auto 40px;
            position: relative;
            border-top: 1px solid $colors_base;

        }

        .views-row {
            width: 100%;
            padding: 25px 0;
            font-size: 20px;
            border-bottom: 1px solid $colors_base;
            cursor: pointer;
            overflow: hidden;

            .views-field-title {
            float: left;
            font-weight: bold;

            h3 {
                margin-bottom: 10px;
            }

            p {
                color: $colors_base;
                font-weight: normal;
            }
            }

            .views-field-view-node {
            float: right;
            font-size: 16px;

                a {
                    @include button_green();
                }
            }

            a {
                color: $color_text_grey;
                text-decoration: none;
            }
        }

    .item-list {
        text-align: center;
        width: 100%;
        float: left;
        margin-bottom: 60px;

        li {
        margin-left: 0;
        padding: 0;

        a {
            background-color: $colors_red;
            color: $colors_white;
            text-decoration: none;
            height: 50px;
            line-height: 50px;
            border-radius: 10px;
            padding: 0 20px;
            display: inline-block;
        }
        }
    }
    }

    #block-views-alle-vacatures-block {
        background-color: $colors_green;
        color: $colors_white;
        padding: 40px 0;
        overflow: hidden;

        h2,
        .view-content {
            max-width: 980px;
            width: $default_width;
            margin: 0 auto;
            position: relative;
        }

        h2 {
            margin-bottom: 30px;
            font-size: 34px;
        }

        .view-content {
            border-top: 2px solid rgba(255, 255, 255, 0.5);
        }

        .views-row {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            border-bottom: 2px solid rgba(255, 255, 255, 0.5);
            cursor: pointer;

            .views-field-title {
                float: left;
                font-weight: bold;
            }

            .views-field-view-node {
                float: right;
                font-size: 16px;

                a {
                    transition: all 0.3s;
                }
            }

            a {
                color: $colors_white;
                text-decoration: none;
            }

            &:hover {
                .views-field-view-node {
                    line-height: 37px;

                    a {
                    @include button_red();
                    height: 30px;
                    line-height: 30px;
                    }
                }
            }
        }
    }
}