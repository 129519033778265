
@keyframes menuIn {
    0% { 
        // height: 0px; 
        // -ms-transform: translateY(0.9);
        // -webkit-transform: translateY(0.9);
        // -moz-transform: translateY(0.9);
        // -o-transform: translateY(0.9);
        // transform: scale(0.9); 
    }
    100% { 
        // height: ($header_height - $menu_margin); 
        // -ms-transform: translate(1);
        // -webkit-transform: translate(1);
        // -moz-transform: translate(1);
        // -o-transform: translate(1);
        // transform: scale(1);
        border-top: 1px solid $colors_grey_light;
    }
}


@keyframes menuOut{
    0% { 
        // height: ($header_height - $menu_margin); 
        // -ms-transform: translateY(1);
        // -webkit-transform: translateY(1);
        // -moz-transform: translateY(1);
        // -o-transform: translateY(1);
        // transform: scaleY(1); 
        border-top: 1px solid $colors_grey_light; 
    }
    50% { 
        // height: ($header_height - $menu_margin); 
        // -ms-transform: translateY(1);
        // -webkit-transform: translateY(1);
        // -moz-transform: translateY(1);
        // -o-transform: translateY(1);
        // transform: scaleY(1); 
        border-top: 1px solid $colors_grey_light; 
    }
    100% { 
        // height: 0px; 
        // -ms-transform: translateY(0.9);
        // -webkit-transform: translateY(0.9);
        // -moz-transform: translateY(0.9);
        // -o-transform: translateY(0.9);
        // transform: scaleY(0.9); 
    }
}


#header {
    height: auto; 
    position: relative;
    
    nav {
        height: $header_height;
        width: 100%;
        float: left;
        transition: height 0.2s;
        
        .logobar {
            height: $header_height;
            float: left;
            
            img {
                margin-top: 15px;
                height: ($header_height - 30);
                transition: height 0.2s;
            }
        }
        
        .main-menu {
            width: auto;
            float: right;
            
            .block {
                float: right;
            }
            #block-system-main-menu {
                position: absolute;
                left: 50%;
                margin-left: calc(-25%);
            }
            
            
            .menu-name-menu-second-main-menu {
                margin: 0 10px 0 150px;
                
                .menu a {
                    @include button_red();
                }
            }
            
            .menu {
                margin-top: $menu_margin;
                height: ($header_height - $menu_margin);
                transition: margin 0.2s;
                
                li {
                    height: 65px;
                    line-height: 65px;
                    margin-left: 20px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    float: left;
                    list-style: none;
                    position: relative;
                    padding-right: 0;
                    padding-top: 0;
                    
                    &.active-trail a {
                        color: $color_text_grey;
                        font-weight: bold;
                    }
                    
                    a {
                        color: $colors_base;
                        text-decoration: none;
                        font-weight: normal;
                        padding: 0 10px;
                        font-size: 18px;
                        transition: color 0.5s;
                        display: block;
                        
                        &:hover,
                        &:active {
                            color: $colors_red;
                        }
                    }
                    
                    .menu {
                        width: 200px;
                        height: auto;
                        position: absolute;
                        top: 65px;
                        left: 50%;
                        // overflow: hidden;
                        margin: 0;
                        margin-left: -100px;
                        padding-top: 5px;
                        text-align: center;
                        // -ms-transform: translateY(0);
                        // -webkit-transform: translateY(0);
                        // -moz-transform: translateY(0);
                        // -o-transform: translateY(0);
                        // transform: scaleY(0);
                        opacity: 0;
                        background-color: $colors_white;
                        -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.1);
                        box-shadow: 0 2px 8px rgba(0,0,0,.1);
                        line-height: ($header_height - $menu_margin);
                        z-index: 99;
                        transition: all 0.3s;
                        height: 10px;
                        overflow: hidden;
                        // animation-name: menuIn;
                        // animation-duration: 0.3s;
                        // animation-fill-mode: backwards;

                        &:after {
                          content: '';
                          position: absolute;
                          top: 0;
                          left: 50%;
                          width: 0;
                          height: 0;
                          border: 10px solid transparent;
                          border-bottom-color: $colors_grey_light;
                          border-top: 0;
                          margin-left: -10px;
                          margin-top: -10px;
                        }
                        
                        
                        &.active {
                            opacity: 1;
                            // transform: scale(1);
                            animation-name: menuIn;
                            animation-delay: 0s;
                            animation-duration: 0.3s;
                            height: auto;
                            
                            // height: ($header_height - $menu_margin); 
                            // -ms-transform: translateY(1);
                            // -webkit-transform: translateY(1);
                            // -moz-transform: translateY(1);
                            // -o-transform: translateY(1);
                            // transform: scaleY(1); 
                            border-top: 1px solid $colors_grey_light;
                            border-bottom: 3px solid #98c010;
                        }
                        
                        li {
                            display: block;
                            float: none;
                            margin-top: 0;
                            margin-left: 0;
                            margin-bottom: 5px;
                            height: 34px;
                            line-height: 34px;

                            &.active-trail a {
                              color: $color_text_grey;
                              font-weight: bold;
                            }
                            
                            a {
                                font-size: 0.9em;

                                &.menu_icon {
                                    padding-left: 0px;
                                    height: 40px;
                                    margin-top: 0px;
                                    line-height: 40px;
                                    background: none;
                                    
                                    &:hover {
                                        background-position-y: 0px;
                                    }

                                    &:before {
                                        content: "";
                                        width: 35px;
                                        height: 25px;
                                        background: transparent url(../images/submenu-hond.png) 0px 0 no-repeat;
                                        margin-right: 5px;
                                        background-size: contain;
                                        display: inline-block;
                                        vertical-align: middle
                                    }
                                    &.menu-508:before,
                                    &.menu-590:before {
                                        background: transparent url(../images/submenu-kat.png) 0 0px no-repeat;
                                        background-size: contain;
                                    }
                                    &.menu-510:before {
                                       background: transparent url(../images/submenu-voedingswijzer.png) 0px center no-repeat;
                                       background-size: contain;
                                    }
                                }

                                &.menu-509.active-trail.active,
                                &.menu-592.active-trail.active,
                                &.menu-1244.active-trail.active {
                                  font-weight: 600;
                                }

                            }
                        }
                    }
                }
            }
            .block-search {
                height: $search_height;
                width: $search_height;
                position: relative;
                margin-top: 86px;
                transition: margin 0.2s;
                
                form {
                    $form-width: 500px;
                    
                    video {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        min-width: 100%;
                        min-height: 100%;
                        width: auto;
                        height: auto;
                        z-index: -100;
                        -ms-transform: translateX(-50%) translateY(-50%);
                        -moz-transform: translateX(-50%) translateY(-50%);
                        -webkit-transform: translateX(-50%) translateY(-50%);
                        transform: translateX(-50%) translateY(-50%);
                        background: url(polina.jpg) no-repeat;
                        background-size: cover; 
                        opacity: 0.6;
                    }
                    
                    &.search-open-window {
                        position: fixed;
                        z-index: 100;
                        background-color: $colors_grey_bg;
                        width: 100vw;
                        height: 100vh;
                        top: 0;
                        left: 0;
                        
                        .container-inline {
                            &:before {
                                content: "";
                                position: absolute;
                                top: 40px;
                                right: 35px;
                                width: 40px;
                                height: 40px;
                                background: transparent url(../images/icon-close.png) center center no-repeat;
                                background-size: cover;
                                cursor: pointer;
                            }
                        }
                        
                        .form-item {
                            top: 25vh;
                            width: $form-width;
                            max-width: 100%;
                            left: 50%;
                            margin-left: calc(-#{$form-width} / 2 );
                            display: block;
                            position: relative;
                            border-bottom: 2px solid $colors_white;
                            z-index: 4;
                            
                            &:before, &:after {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 2px;
                                height: 10px;
                                background-color: $colors_white;
                            }
                            &:after {
                                left: initial;
                                right: 0;
                            }
                            input {
                                width: calc(#{$form-width} - 100px);
                                padding: 10px 20px;
                                border: 0 none;
                                background-color: transparent;
                                font-size: 24px;
                                outline: none;
                                color: $colors_white;
                            }
                        }
                        .form-actions {
                            top: 25vh;
                            width: 45px;
                            left: 50%;
                            margin-left: calc( calc(#{$form-width} / 2) - 50px);
                            display: block;
                            position: absolute;
                            z-index: 4;

                            input[type="submit"] {
                                @include in_icon('../images/icon-search.png', transparent);
                                background-size: 16px 16px;
                                border: 0 none;
                                margin-top: 20px;
                                margin-right: 10px;
                                float: right;
                            }
                        }             
                    
                    }
                    .form-item {
                        display: none;
                    }
                }
                
                /*.form-item {
                    width: 0;
                    overflow: hidden;
                    border-radius: ($search_height / 2);
                    display: block;
                    transition: all 0.5s;
                    opacity: 0;
                    margin: 0 0 0 0;
                    position: absolute;
                    right: 0;
                    z-index: 2;
                    
                    &.search-open {
                        width: 170px;
                        opacity: 1;
                    }
                    
                    input {
                        border: 0 none;
                        height: $search_height;
                        border-radius: ($search_height / 2);
                        width: 170px;
                        background-color: $colors_green_light;
                        box-sizing: border-box;
                        padding: 0 0 0 15px;
                        color: #ffffff;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 14px;
                        outline:none;
                    }
                }*/
                .form-actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 3;
                    
                    input[type="submit"] {
                        display: block;
                        width: $search_height;
                        height: $search_height;
                        border-radius: ($search_height / 2);
                        @include in_icon('../images/icon-search.png', $colors_green);
                        background-size: 16px 16px;
                        border: 0 none;
                        text-indent: -10000px;
                        outline:none;
                        cursor: pointer;
                        box-sizing: border-box;
                    }
                }
            }
            
            .block-extra-locale {
                position: absolute;
                top: 20px;
                right: 0;
                display: none;
                z-index: 99;
            }
        }
    }
    
    &.fixed {
        margin-top: $header_height;
        
        nav {
            width: $default_width;
            height: $header_height;
            position: fixed;
            top: 0px;
            left: 0;
            z-index: 120;
            background-color: rgba(255, 255, 255, 1);
            -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.1);
            box-shadow: 0 2px 8px rgba(0,0,0,.1);
        }
        
    }
    
    .view-home-header {
        width: 100%;
        height: $header_banner_height;
        position: relative;
        
        .views-field-field-startafbeelding, .home-bg-image {
            width: 100%;
            height: $header_banner_height;
            max-height: calc(100vh - 145px);
            background-color: $colors_grey_bg;
            transition: opacity 0.5s;
        }
        .home-bg-image {
            position: relative;
        }
        
        .views-field-field-video-titel {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: #ffffff;
            top: 170px;
            left: 0;
            text-align: center;
            font-family: 'Sniglet', sans-serif;
            font-size: 90px;
            line-height: 80px;
            text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
            
            .field-content {
                max-width: 600px;
                margin: 0 auto;
            }
        }
    }
    .view-header-animal-choice {
        position: absolute;
        z-index: 99;
        width: 100%;
        color: #ffffff;
        left: 0;
        top: 370px;

        .view-content {
            max-width: 350px;
            margin: 0 auto;

            .views-row  { 
                width: 50%;
                display: block;
                float: left;
                cursor: pointer;

                .field-content {
                    text-align: center;
                }
                .views-field-field-dier-afbeelding {
                    margin-bottom: 15px;
                }
                
                a { 
                    text-align: center;
                    font-family: 'Sniglet', sans-serif;
                    font-size: 50px;
                    color: #ffffff;
                    text-decoration: none;
                    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                    text-transform:lowercase;

                    &:after {
                        content: " \203A ";
                        font-family: 'Sniglet', sans-serif;
                        font-size: 60px;
                        color: #ffffff;
                    }
                    
                }
            }
        }
    
    }
    
}

$admin_menu_height: 29px;
.admin-menu #header.fixed nav {
    top: $admin_menu_height;
    
    .menu.active {
        // top: ($header_height + $admin_menu_height);
    }
}


/* 1320px */
@media all and (max-width: $breakpoints_xl) {
    @include responsive_header(125px, 18px, 50px);
}
/* 1160px */
@media all and (max-width: $breakpoints_l) {
    @include responsive_header(100px, 16px, 15px, 26px);
    
    #header nav .main-menu .block-extra-locale {
        position: relative;
        margin-top: 40px;
        top: 0;
        margin-left: 10px;
    }
    #header nav .main-menu .menu li,
    #header nav .main-menu .menu-name-menu-second-main-menu {
        margin-left: 0px;
    }
}

@media all and (max-width: $breakpoints_m) {
    @include responsive_header(100px, 16px, 0px, 33px);
    
    #header nav .main-menu #block-system-main-menu {
        left: 40%;
    }
    #header nav .main-menu .block-extra-locale {
        top: 8px;
        right: -60px;
    }
}
@media all and (max-width: $breakpoints_tablet) {
    $admin_menu_height: 47px;
    .admin-menu #header.fixed nav {
        top: $admin_menu_height;

        .menu.active {
            top: ($header_height + $admin_menu_height);
        }
    }
}

#header.fixed nav.ie-bar {
    height: 85px;
    
    .logobar img {
        height: 55px;
    }
    .main-menu {
        .menu {
            margin-top: 0;
            
            li .menu.active {
                top: 85px;
            }
        }
        .block-search {
            margin-top: 26px;
        }
    }
}


@media all and (max-width: 768px) {


    .slicknav_menu {
        display: none;
    }
    .admin-menu #header.fixed nav {
        top: 0;
    }

}