/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 7-sep-2016, 9:59:25
    Author     : keescornelisse1
*/

$article_height: 400px;
$populair_count: 5;
$more_articles: 4;
.node-type-overzicht {
    .second-main .block-center {
        max-width: 100% !important;
    }
}
.view-populair-bij-prins, 
.view-artikel-overzicht {
    /*margin-left: -15px;
    margin-right: -15px;*/
    max-width: 100%;
    
    .views-row {
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        margin-bottom: 30px;
    }

    &.overzicht-1, &.overzicht-4 {
        .views-row-1 {
            width: 100%;
            position: relative;
            height: $article_height;

            .article-title {
                width: 33.3333%;
                height: $article_height;
                float: left;
                background-color: $colors_grey_bg;
                color: $colors_white;
                text-align: center;
                font-family: 'Sniglet', sans-serif;
                font-size: 32px;
                box-sizing: border-box;
                padding: 20px;
                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .views-field-field-opgemaakte-titel-regel {

                    &-1 {
                        line-height: normal;
                        color: $colors_green;
                        font-size: 32px;
                        width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    &-2 {
                        line-height: 1.1;
                        font-size: 52px;
                        align-self: center;
                        width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 35px 0 35px 24px;
                    border-color: transparent $colors_grey_bg;
                    display: block;
                    width: 0;
                    z-index: 1;
                    right: -24px;
                    top: 165px;
                }
            }
            .views-field-field-startafbeelding {
                width: 66.6667%;
                float: left;
                height: $article_height;
                overflow: hidden;

                .home-bg-image {
                    width: 100%;
                    height: $article_height;
                    background-position: center center;

                 -webkit-transition: all 1s ease;
                    -moz-transition: all 1s ease;
                      -o-transition: all 1s ease;
                     -ms-transition: all 1s ease;
                         transition: all 1s ease;



                }
            }
            .views-field-view-node {
                position: absolute;
                right: 35px;
                bottom: 20px;

                a {
                    color: #ffffff;
                    text-decoration: none;
                    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                    font-weight: bold;

                    &:after {
                        content: " \203A ";
                        transition: all 0.3s;
                    }

                    &:hover:after {
                        padding-left: 3px;
                    }
                }



            }

            &:hover {
                cursor: pointer;

                .home-bg-image {
                    transform: scale(1.1);
                }
            }
        }
    }

    &.overzicht-2, &.overzicht-3, &.overzicht-5 {

        .views-row {
            width: 33.3333%;
            float: left;
            height: $article_height;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;

            .views-field-field-startafbeelding {
                width: 100%;
                position: relative;
                height: $article_height;
                overflow: hidden;
                background-color: $colors_grey_bg;

                .home-bg-image {
                    width: 100%;
                    height: $article_height;
                    background-position: center center;

                    -webkit-transition: all 1s ease;
                    -moz-transition: all 1s ease;
                      -o-transition: all 1s ease;
                     -ms-transition: all 1s ease;
                         transition: all 1s ease;
                }
            }
            .article-title {
                position: absolute;
                bottom: 30px;
                left: 45px;
                z-index: 3;
                color: $colors_white;
                line-height: 28px;
                text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                width: 90%;

                .views-field-field-opgemaakte-titel-regel {

                    &-1 {
                        font-size: 20px;
                        font-weight: normal;
                        width: 90%;
                    }
                    &-2 {
                        font-size: 24px;
                        font-weight: bold;
                        max-width: 380px;
                        width: 90%;
                    }

                }
            }
            .views-field-view-node, .views-field-title {
                display: none;
            }

            &:hover .home-bg-image {
                opacity: 0.8;
                transform: scale(1.1);
            }
            
            
        }
        
        &.view-display-id-block_5 {
            margin: 30px 0 50px 0;
            float: left;
            width: calc(100% + 30px);
            
            .item-list {
                text-align: center;
                width: 100%;
                float: left;
                margin-bottom: 60px;
                
                li {
                    margin-left: 0;
                    padding: 0;
                     
                    a {
                        background-color: $colors_red;
                        color: $colors_white;
                        text-decoration: none;
                        height: 50px;
                        line-height: 50px;
                        border-radius: 10px;
                        padding: 0 20px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    &.overzicht-5 {
        max-width: $default_max_width;
        margin: 0 auto;
        position: relative;
        
        .views-row {
            max-width: $default_max_width / $populair_count;
            max-height: ($default_max_width / $populair_count)-30; 
            width: 20%;
            margin-bottom: 0;
            
            .views-field-field-startafbeelding, .views-field-field-startafbeelding .home-bg-image {
                height: ($default_max_width / $populair_count)-30; 
            }
            .article-title {
                bottom: 20px;
                left: 35px;
                line-height: 25px;
                
                .views-field-field-opgemaakte-titel-regel {
                    &-1, &-2 {
                        font-size: 16px;
                        max-width: 90%;
                    }
                }
            }
        }
    }
    &.view-meer-artikelen-over {
        margin-left: 0;
        margin-right: 0;
        
        .views-row {
            max-width: $default_max_width / $more_articles;
            max-height: ($default_max_width / $more_articles)-30; 
            width: 25%;
            margin-bottom: 0;
            
            .views-field-field-startafbeelding, .views-field-field-startafbeelding .home-bg-image {
                height: ($default_max_width / $more_articles)-30; 
            }
        }
    }

}
#block-views-artikel-overzicht-block-2 {
    overflow: hidden;
}

/* Populair bij Prins */
#block-views-populair-bij-prins-block-4, 
#block-views-artikel-overzicht-block-4 {
    float: left;
    width: 100%;
    position: relative;
    
    h2 {
        height: 90px;
        line-height: 90px;
        text-align: center;
        background-color: $colors_grey_light;
        color: #636363;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s;
        
        &:hover {
            opacity: 0.7;
        }
        
        &:after {
            content: "";
            width: 18px;
            height: 7px;
            
            @include in_icon('../images/icon-populair.png', transparent); 
            display: inline-block;
            position: relative;
            top: -5px;
            left: 10px;
            transition: transform 0.5s;
        }
    }
    
    .content {
        position: absolute;
        bottom: 90px;
        width: 100%;
        height: 0;
        transition: all 0.3s;
        overflow: hidden;
        -webkit-box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.15);
        box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.15);
        opacity: 0;
        z-index: 99;
    }
    &.active {
        .content {
            height: ($default_max_width / $populair_count)-30; 
            opacity: 1;
            background-color: $colors_grey_light;
            padding: 30px 0;
        }
        h2 {
            &:after {
                transform: rotateX(180deg);
                -ms-transform: rotateX(180deg);
                -webkit-transform: rotateX(180deg);
            }
        }
    }
}
        

.filter-block {
    &.fixed-under-bar {
        position: fixed;
        width: 100%;
        top: 85px;
        left: 0;
        z-index: 99;
        background: #ffffff;
    }
}
#block-global-filter-global-filter-3 {
    &.fixed-under-bar {
        position: fixed;
        width: 100%;
        top: 85px;
        z-index: 99;
        background: #ffffff;
    }
}
.filter-block.fixed-under-bar #block-global-filter-global-filter-1 .content,
.filter-block.fixed-under-bar #block-global-filter-global-filter-2 .content,
#block-global-filter-global-filter-3.fixed-under-bar .content {
    margin: 15px 0;
}

#block-views-artikel-overzicht-block-5 {
    overflow: hidden;
}
.admin-menu #block-global-filter-global-filter-3.fixed-under-bar {
    top: 123px;
}
#block-global-filter-global-filter {
    

    &-1 .content, &-2 .content, &-3 .content {
        margin: 20px 0;
        a {
            text-decoration: none;
            color: $colors_base;
            padding: 3px 8px;
            border: 1px solid transparent;
        }
        a:hover, .active a {
            color: $colors_red;
        }
        li {
            margin-right: 0;
        }
    }
    
    &-1 .content {
        float: left;
        text-transform: uppercase;
        padding-left: 15px;
        
        a {
            margin-right: 5px;
            height: 30px;
            line-height: 30px;
            box-sizing: border-box;
        }
        li:nth-child(2) {
            a {
                padding-left: 40px;
                background: transparent url('../images/icon-kat.png') 0 -31px no-repeat;
            }
            &.active a, a:hover {
                background-position-y: -1px;
            }
        }
        li:nth-child(3) {
            a {
                padding-left: 40px;
                background: transparent url('../images/icon-hond.png') 0 -31px no-repeat;
            }
            &.active a, a:hover {
                background-position-y: -1px;
            }
        }
        .active a {
            font-weight: bold;
        }
    }
    &-2 .content, &-3 .content {
        float: right;
        padding-right: 15px;
        
        a {
            margin-left: 5px;
            border-radius: 18px;
        }
        a:hover {
            border: 1px solid $colors_red;
        }
        .active a {
            color: $colors_white;
            background-color: $colors_red;
        }
    }
}
#block-global-filter-global-filter-3 .content {
    float: none;
    margin: 20px auto;
    position: relative;
    width: auto;
    text-align: center;
}