/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 8-dec-2016, 15:35:32
    Author     : keescornelisse1
*/

#block-block-6 {
    background-color: $color_blue_bg_dark;
    padding: 40px 0;
    color: white;
    text-align: center;

    h1 {
        font-size: 2.5em;
        line-height: 2.3em;
    }

    h2 {

    }
}

.voedingswijzer {
    width: 100%;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.3s;
    
    &.freeze {
        opacity: 0.3;
        
        .voedingswijzer-step .answers li {
            cursor: default;
        }
    }
    
    h3 {
        
    }
    .voedingswijzer-header {
        text-align: center;
        color: $color_text_grey;
        padding: 40px 0 20px;
        
        h1 {
            color: $colors_grey_bg;
            font-size: 30px;
            margin-bottom: 20px;
            
           /* &:before {
                content: "";
                width: 38px;
                height: 30px;
                display: inline-block;
                margin-right: 15px;
                background: transparent url('../images/voedingswijzer.png') center center no-repeat;
                vertical-align: bottom;
            } */
        }
        h3 {
            font-size: 20px;
            color: $colors_base;
            max-width: 800px;
            margin: 0 auto;
        }
    }
    
    .voedingswijzer-wrapper {
        transition: transform 0.3s;
        width: 100vw;
    }

    .voedingswijzer-wrapper.no-animation {
        transition: none;
    }   
    
    .voedingswijzer-step {
        display: block;
        float: left;
        text-align: center;
        
        h3 {
            color: $color_text_grey;
        }
        h4 {
            margin-top: 20px;
            margin-bottom: 20px;

            & > span {
                background-color: $colors_red;
                border-radius: 50%;
                color: white;
                padding: .5em .4em;
                margin-right: 20px;
            }
        }
        .answers {
            text-align: center;
            margin: 20px 0;
            
            li {
                display: inline-block;                
                color: $colors_white;                
                margin: 10px 10px;
                cursor: pointer;
                background-color: $colors_green;
                border-radius: 8px;

                &.active {
                    background-color: $color_green_lighter;
                    color: $color_text_grey;
                    font-weight: bold;
                }

                &.has-description .answer-title::after {
                    content: 'i';
                    display: inline-block;
                    border-radius: 50%;
                    background-color: black;
                    color: white;
                    padding: 0 11px;
                    font-weight: bold;
                    margin-left: 10px;
                    pointer-events: none;
                }

                .answer-title {
                    position: relative;
                    display: inline-block;
                    padding: 10px 20px;
                    
                    &:hover .description {
                        visibility: visible;
                    }

                    .description {
                        visibility: hidden;
                        width: 200px;
                        background-color: black;
                        color: #fff;
                        text-align: center;
                        padding: 10px 0;
                        border-radius: 6px;
                        font-size: .9em;
                        bottom: 100%;
                        left: 50%; 
                        margin-left: -90px;
                        font-weight: normal;

                        /* Position the tooltip text - see examples below! */
                        position: absolute;
                        z-index: 1;

                        &::after {
                            content: " ";
                            position: absolute;
                            top: 100%; /* At the bottom of the tooltip */
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: black transparent transparent transparent;
                        }
                    }
                }
            }
        }
    }

    .step-products {
        .node-product {
            float: left;
            width: 25%;
            padding: 0 15px;
            box-sizing: border-box;
            text-align: center;
            margin-bottom: 40px;
            min-height: 410px;
            font-size: .8em;
            
            .field-name-field-product-afbeelding {
                img {
                    max-height: 175px;
                    width: auto;
                }
            }
            .field-name-field-productlijn {
                color: $color_text_grey;
            }
            .views-field-field-detailpagina-titel a, 
            .views-field-title a {
                color: $color_text_grey;
                font-weight: bold;                
                text-decoration: none;
            }
        }
    }

    #voedingswijzer-sidebar {
        float: left;
        width: 30%;
        background-color: $colors_grey_light;        
        margin-bottom: 50px;
        box-sizing: border-box;

        .answers {
            text-align: left;

            li {

            }
        }
    }

    .step-products {
        float: left;
        width: 70%
    }
    
}