/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 7-sep-2016, 9:47:59
    Author     : keescornelisse1
*/

$article_more_about_height: 260px;

.node-type-prins-artikel,
.node-type-tips-info {
    .view-header-artikel {
        width: 100%;
        height: $header_banner_height;
        position: relative;
        background-color: $colors_grey_bg;
        
        .views-field-field-startafbeelding, .home-bg-image {
            width: 100%;
            height: $header_banner_height;
            position: relative;
            opacity: 0.64;
        }
        
        .backlink {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: $colors_white;
            top: calc(35vh - 125px);
            left: 0;
            text-align: center;
            
            @include backlink();
        }
        
        .views-field-title {
            position: absolute;
            z-index: 99;
            width: 100%;
            color: $colors_white;
            top: calc(40vh - 125px);
            left: 0;
            text-align: center;
            
            .field-content {
                max-width: 800px;
                margin: 0 auto;
                display: block;
            }
            
            h1 {
                font-size: 64px;
                line-height: 72px;
                font-weight: bold;
                margin-bottom: 35px;
            }
            h2 {
                font-size: 24px;
                line-height: 36px;
                font-weight: bold;
            }
        }
        
        .header-down-btn {
            @include header_down_btn();
        } 
        
    }
    #block-views-artikel-auteur-header-block {
        position: absolute;
        width: 100%;
        bottom: 80px; 
        
        .view-artikel-auteur-header {
            max-width: $default_width;
            position: relative;
            margin: 0 auto;
            width: 100%;
            
            text-align: center;
            color: #ffffff;
        }
        
        .views-field-field-auteur-afbeelding {
            img {
                border-radius: 33px;
                border: 2px solid $colors_white;
                
            }
        }
    }
    
    .addthis {
        max-width: 980px;
        margin: 0 auto 20px;
        position: relative;
        text-align: right;
    }
    
    .field-name-body {
        @include body_style();
        padding-top: 60px;
        
        & .field-item > *:nth-last-child(1) {
            padding-bottom: 40px;
            border-bottom: 3px solid #eeeeee;
        }
    }
    .field-name-auteur-twice {
        width: 200px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        padding: 40px 0 20px 40px;
        float: left;
        z-index: 99;

        @media all and (min-width: 1280px) {
            margin-left: 100px;
        }

        @media all and (max-width: 500px) {
            width: 100%;
            padding-top: 50px;

            &>.field-label,
            &>.field-items {
                float: none;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .field-label {
            font-weight: normal;
            font-style: italic;
            text-align: right;
            line-height: 45px;
            padding-right: 7px;
        }

        .field-name-field-startafbeelding {
            float: left;
            margin-right: 10px;

            img {
                width: 45px;
                height: auto;
                border-radius: 50%;
            }
        }
        .field-name-title {
            float: left;
            line-height: 45px;

            a {
                text-decoration: none;
                color: #6a6a6a;
            }
        }
    }
    .field-name-field-koppeling-expert {
        width: 100%;
        max-width: 980px;
        padding-bottom: 50px;
        margin: 0 auto;

        & > .field-label {
            margin-bottom: 10px;
        }

        .view-mode-auteur_samenvatting {
            &.ds-2col>.group-left {
                width: auto;
                padding-right: 20px;
                box-sizing: border-box;

                @media all and (max-width: 768px) {
                    width: 30%;
                }
            }
            &.ds-2col>.group-right {
                @media all and (max-width: 768px) {
                    width: 70%;
                }
                @media all and (min-width: 980px) {
                    width: auto;
                    float: none;
                }
            }

            @media all and (max-width: 1024px) {
                padding-left: 20px;
                padding-right: 20px;

                .field-name-body {
                    padding: 0 0 0 0;
                }
            }

            .field-name-field-startafbeelding {
                img {
                    border-radius: 50%;
                    max-width: 200px;
                    width: 100%;
                    height: auto;
                }
            }
            .field-name-body {
                padding-top: 0;
                margin-bottom: 0;
                padding-right: 30px;

                p {
                    font-size: 15px;
                }
            }
            .field-name-node-link {
                a {
                    @include button_green();
                }
            }
        }
    }

    
    .main-spanner {
        .view-artikel-auteur-header {
            max-width: 860px;
            margin: 0 auto;
            position: relative;
            text-align: center;
            
            .views-field-field-auteur-afbeelding {
                margin-top: 40px;
                
                img {
                    border-radius: 33px;
                    border: 2px solid $colors_white;

                }
            }
            .views-field-nothing {
                padding: 20px;
                font-size: 20px;
                
                p {
                    max-width: 600px;
                    margin: 0 auto;
                    margin-bottom: 15px;
                    line-height: 30px;
                    
                    &:nth-child(1) {
                        color: $color_text_grey;
                    }
                    &:nth-child(2) {
                        padding: 0 30px;
                        box-sizing: border-box;
                    }
                }
                .auteur_team_contact {
                    list-style: none;
                    max-width: 750px;
                    margin: 20px auto 60px;
                    background-color: $colors_grey_bg;
                    height: 80px;
                    line-height: 80px;
                    text-align: center;
                    color: $colors_white;
                    
                    li {
                        display: inline-block;
                        padding: 0 30px;
                        
                        @include icon('phone-2');
                    }
                    #hf-chat {
                        cursor: pointer;
                    }
                }
            }
            
            
        }
        
    }
    #block-views-meer-artikelen-over-block,
    #block-views-meer-artikelen-over-block-2,
    .view-alles-over-jouw-dier {
        h2 {
            text-align: center;
            font-size: 30px;
            line-height: 38px;
            font-weight: normal;
            color: $color_text_grey;
            text-transform: initial;
        }
        .view-header {
            text-align: center;
            font-size: 30px;
            line-height: 38px;
            color: $color_text_grey;
            
            .view-meer-artikelen- .views-row {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                height: auto;
                
                .field-content {
                    font-weight: 700;
                    margin-bottom: 10px; /* 40px */
                    font-size: 0;
                }
            }
            p {
                font-weight: 700;
            }
        }
        .content {
            max-width: $default_max_width;
            margin: 0 auto;
            width: 100%;
            position: relative;
        }

        width: 100%;
        float: left;
        margin: 80px 0;
    }
    #block-views-9a023b3b304e1f4576dfdc10ac25064c {
        width: 100%;
        float: left;
    }
    #block-views-alles-over-jouw-dier-block {
        display: none;
    }
    .view-alles-over-jouw-dier {
        background-color: $colors_grey_light;
        padding-top: 20px;
        margin-bottom: 0;
        margin-top: 0;
        
        .view-header {
            text-transform: lowercase;
            margin-bottom: 20px;
        }
        .content {
            max-width: 100%;
        }
    }
    .view-overzicht-levensfasen-onder-artikel {
        background-color: $colors_grey_bg;
        overflow: hidden;
        width: 100%;
        
        .view-content {
            width: 100%;
            height: $article_more_about_height;
            max-width: $default_max_width;
            margin: 0 auto;
            
            .views-row {
                list-style: none;
                width: 25%;
                height: $article_more_about_height;
                float: left;
                margin: 0;
                padding: 0 15px;
                box-sizing: border-box;
                position: relative;
                background-size: cover;
                background-position: center bottom;
                
                h3, p, .cta-link a {
                    position: relative;
                    z-index: 1;
                    padding-left: 40px;
                    color: #ffffff;
                    padding-right: 40px;
                }
                h3 {
                    padding-top: 30px;
                    font-size: 28px;
                    margin-bottom: 10px;
                    
                    a {
                        text-decoration: none;
                        color: #ffffff;
                    }
                }
                p {
                    line-height: 24px;
                    font-size: 15px;
                    margin-bottom: 20px;
                }
                .cta-link a {
                    font-weight: bold;
                    text-decoration: none;
                    font-size: 18px;
                    
                    &:after {
                        content: " \203A ";
                        transition: all 0.3s;
                    }
                    
                    &:hover:after {
                        padding-left: 5px;
                    }
                }
            }
            
        }
        &.diersoort-kat {
            .views-row-0 {
                background-image: url('../images/icon-cta-1.png');
            }
            .views-row-1 {
                background-image: url('../images/icon-cta-2.png');
            }
            .views-row-2 {
                background-image: url('../images/icon-cta-3.png');
            }
            .views-row-3 {
                background-image: url('../images/icon-cta-4.png');
            }
        }
        &.diersoort-hond {
            .views-row-0 {
                background-image: url('../images/icon-cta-1.png');
            }
            .views-row-1 {
                background-image: url('../images/icon-cta-6.png');
            }
            .views-row-2 {
                background-image: url('../images/icon-cta-7.png');
            }
            .views-row-3 {
                background-image: url('../images/icon-cta-8.png');
            }
        }
    }
    
    #block-block-10 {
        width: 100%;
        float: left;
    }
}
