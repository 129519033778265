


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import 'https://fonts.googleapis.com/css?family=Sniglet';

@include placeholder($colors_white); 
@include drupal_tabs();

* {
    margin: 0;
    padding: 0;
}
table tbody {
    border-top: 0;
}
body {
    font-family: 'Open Sans', Verdana, sans-serif;
    font-size: $default_font_size;
    line-height: $default_font_lineheight;
    color: $colors_base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

.header-interactive .block .views-field:not(.views-field-field-startafbeelding),
.node-type-overzicht .block .views-row {
    opacity: 0.2;
}
.front .header-interactive .block .views-field {
    opacity: 1;
}

#header nav .loader {
    width: 0;
    height: 5px;
    background-color: $colors_green;
    bottom: 0;
    left: 0;
    position: absolute;
}

.region-block {
    width: $default_width;
    float: left;
}
.region-block .block-center,
#header .navigation-spanner {
    max-width: $default_max_width;
    width: $default_width;
    margin: 0 auto;
    position: relative;
}
#header {
    .menu-block-wrapper {
        @include default_submenu();
    }
    .menu-name-menu-second-main-menu {
        width: auto;
    }
}
.second-main .block-center {
    max-width: $default_width;
    
    .block {
        max-width: $default_max_width;
        width: $default_width;
        margin: 0 auto;
        position: relative;
    }
}
@media all and (max-width: $breakpoints_xxl) {
    .region-block .block-center,
    #header .navigation-spanner {
        max-width: 1280px;
    }
}
@media all and (max-width: $breakpoints_xl) {
    .region-block .block-center,
    #header .navigation-spanner {
        max-width: 1120px;
    }
}
@media all and (max-width: $breakpoints_l) {
    .region-block .block-center,
    #header .navigation-spanner {
        max-width: 1024px;
    }
}
@media all and (max-width: $breakpoints_m) {
    .region-block .block-center,
    #header .navigation-spanner {
        max-width: 980px;
    }
}

i.icon {
    width: 26px;
    height: 26px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    
    @include icon('phone-2');
    @include icon('mail-2');
    @include icon('chat');
}

.block-locale,
.block-extra-locale {
    float: left;
    background-color: $colors_grey_light;
    border-radius: 8px;
    color: $color_grey;
    position: relative;
    overflow: hidden;
    height: 36px;
    width: 150px;
    line-height: 36px;
    padding-top: 36px;
    box-sizing: border-box;
    transition: height 0.3s;
    
    &.language-open {
        height: 252px;
        
        &:after {
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transform: rotate(180deg); /* Safari */
            transform: rotate(180deg);
        }
    }
    
    li {
        display: block;
        height: 36px;
        line-height: 36px;
        width: 100%;
        
        &.active {
            position: absolute;
            top: 0;
            left: 0;
        }
        
        a {
            color: $color_grey;
            text-decoration: none;
            display: block;
            padding: 0 10px;
            transition: all 0.3s;
        
            &:hover {
                background-color: $color_grey;
                color: $colors_white;
            }
        }
    }
    &:after {
        position: absolute;
        top: 15px;
        right: 10px;
        content: ""; 
        width: 0px;
        height: 0px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #2f2f2f;
        transition: transform 0.3s;
    }
}

.page-spanner {
    overflow: hidden;
    width: 100%;
    
    .page {
        transition: all 0.3s;
        
        &.fade-in {
            opacity: 1 !important;
        }
        &.fade-out {
            opacity: 0;
            height: 0;
            overflow: hidden;
            margin: 0 0 !important;
        }
        &.scale-out {
            background-color: $colors_white;
            transform: scale(0.9, 0.9);
            margin: 10% 0; 
        }
    }
}

.node-type-webform .messages {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 1%;
    z-index: 99;
    background: #98c010;
    border: 0 none;
    padding: 10px 40px;
    color: #ffffff;
    text-align: center;
    box-sizing: border-box;
    border-radius: 10px;
}


/*
.node-type-tekstpagina {
    
    .main-content {
        max-width: 960px;
        width: 100%;
        position: relative;
        margin: 0 auto;
    }
    
    h1 {
        color: $color_text_grey;
        margin: 40px 0;
    }
    .field-name-body {
        margin-bottom: 40px;
        
        h2 {
            color: $color_text_grey;
            margin-bottom: 20px;
        }
        h3 {
            color: $color_text_grey;
            margin-bottom: 20px;
        }
        
        p {
            margin-bottom: 20px;
        }
        a {
            color: $colors_red;
        }
    } 
    
}*/


.page-search {
    .region-block .block-center {
        max-width: 100%;
    }
    .region-block .block-center-max {
        max-width: 1024px;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    
    .search-advanced {
        display: none;
    }
    .search-form {
        padding: 20px 0;
        background-color: $colors_grey_bg;
        
        .container-inline  {
            width: 400px;
            height: 80px;
            margin: 0 auto;
            position: relative;
            
            label {
                color: $colors_white;
                text-align: center;
                width: 100%;
                display: inline-block;
                margin-bottom: 10px;
            }
            
            input.form-text {
                width: 310px;
                box-sizing: border-box;
                padding: 8px 15px;
                border: 0 none;
                float: left;
            }
            input.form-submit {
                width: 90px;
                box-sizing: border-box;
                padding: 8px 15px;
                border: 0 none;
                float: right;
                background-color: $colors_green;
                color: $colors_white;
                text-transform: uppercase;
            }
        }
    }
    .pager {
        
        li {
            a {
                color: $colors_red;
                text-decoration: none;
                
                &:hover {
                    color: $color_text_grey;
                }
            }
        }
    }
    
    h2 {
        font-size: 28px;
        text-align: center;
        color: $color_text_grey;
        font-weight: 900;
        margin: 30px 0;
    }
    
    .search-results {
        margin: 40px 0;
        
        li {
            margin-bottom: 25px;
        }
        
        a {
            color: $colors_red;
            text-decoration: none;
        }
        
        .search-snippet-info {
            padding-left: 0;
        }
    }
}

.page-user {
    #user-login {
        max-width: 600px;
        margin: 100px auto;
        position: relative;
        border-radius: 17.5px;
        background-color: #eeeeee;
        box-sizing: border-box;
        padding: 20px 40px;
        
        .form-item input {
            border: 1px solid silver;
            border-radius: 3px;
            background-color: #ffffff;
            padding: 10px 15px;
            font-size: 15px;
        }
        .form-actions input {
            background-color: #e5322d;
            color: #ffffff;
            text-transform: uppercase;
            height: 35px;
            line-height: 35px;
            border-radius: 17.5px;
            border: 0 none;
            display: inline-block;
            vertical-align: middle;
            font-weight: normal;
            font-size: 14px;
            padding: 0 15px;
        }
    }
    
}
/*
.search-open {
    .ui-autocomplete {
        display: none;
    }
    
}*/

body.html .search-open {
    input.form-autocomplete {
        background-image: none;
    }
    .ui-autocomplete {
        display: block;
        padding-top: 25px;
        background: transparent none;
        color: #fff;
        border: 0 none;
        font-family: 'Open Sans', Verdana, sans-serif;
        
        
        .ui-autocomplete-field-group {
            font-style: italic;
            font-size: 13px;
            color: #efefef;
            padding-top: 15px;
            display: inline-block;
        }
        
        li {
            
            a {
                color: #fff;
                padding: 0 0 10px;
                cursor: pointer;
                transition: opacity 0.1s;
                
                .ui-autocomplete-field-term {
                    font-weight: bold;
                    font-style: italic;
                }
                
                &:hover {
                    opacity: 0.7;
                }
                
                &.ui-state-focus {
                    background: transparent none;
                    border: 0 none;
                    margin: 0;
                }
            }
            
            &:last-child {
                a {
                    @include button_red();
                }
            }
        }
    }
}

.adminimal-menu.page-search {
    .search-advanced {
        max-width: $breakpoints_tablet;
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: block;
        
        border: 0 none;
        
        
        legend {
            text-align: center;
            
            a {
                color: #fff
            }
        }
        .fieldset-wrapper {
            width: 390px;
            margin: 0 auto;
            
            label {
                color: #fff;
            }
            .action {
                input {
                    @include button_green ();
                    border: 0 none;
                    font-size: 16px;
                    text-transform: uppercase;
                    -webkit-appearance: none;
                    font-weight: normal;
                }
            }
        }
    }
}




body.html #sliding-popup {
    background: rgba(0,0,0, 0.85);
    padding: 40px 13px;
    box-sizing: border-box;
    
    @media all and (max-width: 768px) {
        padding: 20px 13px 0;
    }
    
    .eu-cookie-compliance-more-button {
        font-size: 17px;
    }
    
    .popup-content {
        padding: 0 10px;
        max-width: $default_max_width;
        width: 100%;
    }
    
    .popup-content #popup-text {
        
        h3, h2 {
            font-size: 24px;
            width: 100%;
        }
        p {
            width: 100%;
        }
        p, a {
            font-size: 17px;
            font-weight: normal;
            margin-bottom: 10px;
            display: inline-block;
        }
        a {
            color: #fff;
        }
        @media all and (max-width: 768px) {
            max-width: 100%;
            
            h3 {
                font-size: 20px;
            }
            
            p, a  {
                font-size: 15px;
            }
            .eu-cookie-compliance-more-button {
                font-size: 15px;
            }
        }
        @media all and (max-width: 320px) {
            h3 {
                font-size: 18px;
            }
            
            p, a  {
                font-size: 13px;
            }
            .eu-cookie-compliance-more-button {
                font-size: 13px;
            }
        }
    }
    .popup-content #popup-buttons {
        float: left;
        width: 100%;
        max-width: 100%;
    }
    .agree-button {
        @include button_green();
        text-transform: none;
    }
    .decline-button {
        @include button_green();
        background-color: $colors_red;
        background-image: none;
        border: 0 none;
        box-shadow: none;
        text-shadow: none;
    }
}


.rtecenter {
    text-align: center;
}