.node-type-blokkenpagina {
  #main {
    max-width: 100%;
  }

  .group-header {
    padding: 40px 0;
    background-color: #25303d;
    text-align: center;

    .field-name-body {
      max-width: $default_max_width;
      width: 100%;
      margin: 40px auto 40px;
      overflow: hidden;
      position: relative;
    }

    h1,
    h2,
    h3,
    p {
      color: #fff;
    }

    h1 {
      color: #ffffff;
      margin-bottom: 40px;
      font-size: 36px;
      line-height: 44px;
    }

    p {
      color: #d0d2d4;
      font-weight: 600;
      font-size: 20px;
      line-height: 34px;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }

  .field-collection-container {
    border-bottom: 0 none;
  }

  .field-name-field-blokken {
    padding-top: 0px;

    .field-collection-view {
      border-bottom: 0 none;
      padding: 40px 0;
      max-width: $default_max_width;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
    }

    @media all and (max-width: calc(#{$default_max_width} + 40px)) {
      .field-item {
        padding-left: 20px;
        padding-right: 20px;

        &.even {
          .group-left {
            padding-right: 20px;
            box-sizing: border-box;
          }
        }
      }
    }

    .field-item.odd {
      background-color: #eeeeee;

      
      @media all and (min-width: 768px) {
        .field-collection-item-field-blokken {
          display: flex;

          .group-left {
            order: 2;

            img {
              float: right;
            }
          }

          .group-right {
            order: 1;
          }
        }
      }
      
    }
    

    .field-name-field-blok-titel {
      margin-bottom: 15px;
    }
    .field-name-field-blok-afbeelding {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .field-item {
      margin-bottom: 0;

      .group-right {
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
    @media all and (max-width: 767px) {
      .field-collection-item-field-blokken {
        display: block;

        .group-left,
        .group-right {
          width: 100%;
          padding-right: 0;

          .field-item {
            padding-left: 0;
            padding-right: 0;
          }

          img {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

}
