/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 6-sep-2016, 8:56:46
    Author     : keescornelisse1
*/

.node-type-home {
    
    .header-interactive {
        overflow: hidden;
        width: 100%;
        height: $header_banner_height;
        position: relative;
        
        .view-home-header {
            @include video_header($header_banner_height, $colors_grey_bg);
        }
        
        &.active-slide {
            .home-bg-image {
                opacity: 0.5;
            }
            .view-home-header .views-field-field-video-titel,
            .view-header-animal-choice {
                opacity: 0 !important;
            }
            #block-views-home-leeftijdenwidget-block,
            #block-views-home-leeftijdenwidget-block-1 {
                opacity: 1;
            }
            #block-views-home-leeftijdenwidget-block.active {
                right: 0%;
            }
            #block-views-home-leeftijdenwidget-block-1.active {
                left: 0%;
            }
        }
    }
    
    /* kat */
    #block-views-home-leeftijdenwidget-block {
        width: 100%;
        height: $header_banner_height;
        right: 100%;
        position: absolute;
        top: 0;
        transition: all 0.5s;
        opacity: 0;
        z-index: 99;
    }
    /* hond */
    #block-views-home-leeftijdenwidget-block-1 {
        width: 100%;
        height: $header_banner_height;
        left: 100%;
        position: absolute;
        top: 0;
        transition: all 0.5s;
        opacity: 0;
        z-index: 99;
    }
    
    .view-home-leeftijdenwidget .slide {
        opacity: 0;
        transition: all 0.5s;
    }
    .view-header-animal-choice {
        transition: all 0.5s;
    }
    .view-home-leeftijdenwidget.slide {
        max-width: $default_max_width;
        width: 100%;
        margin: 0 auto;
        position: relative;
        
        .view-header {
            text-align: center;
            color: $colors_white;
            font-size: 48px;
            line-height: 72px;
            font-weight: bold;
            margin: calc(30vh - 125px) 0 50px;
            text-transform: lowercase;
            text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
            float: left;
            width: 100%;
            
            p:first-letter {
                text-transform: uppercase;
            }
            .slide-close {
                position: absolute;
                top: 40px;
                right: 0;
                width: 40px;
                height: 40px;
                @include in_icon('../images/icon-close.png', transparent); 
                cursor: pointer;
            }
        }
        .view-content {
            padding: 0 80px;
            
            .views-row {
                width: 33%;
                padding: 20px 15px;
                float: left;
                box-sizing: border-box;
                text-align: center;
                color: $colors_white;
                transition: all 0.3s;
                cursor: pointer;
                
                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }
                .views-field-field-usp-afbeelding {
                    margin-bottom: 20px;
                }
                .views-field-title {
                    font-size: 32px;
                    font-weight: bold;
                    margin-bottom: 15px;
                    
                    a {
                        color: $colors_white;
                        text-decoration: none;
                        text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.35);
                    }
                }
                .views-field-body {
                    
                }
            }
        }
    }
    
    
    
    .main-spanner {
        background-color: $colors_grey_light;
        padding: 70px 0 40px;
    }
    
    .content .field-name- {
        
        &title-field {
            font-size: 28px;
            text-align: center;
            color: $color_text_grey;
            font-weight: 900;
            margin-bottom: 30px;
        }
        
        &body {
            max-width: 880px;
            margin: 0 auto;
            font-size: 20px;
            line-height: 30px;
        }
        
    }
    
    .view-usp-s {
        width: 100%;
        margin: 50px 0 0;
        
        .views-row {
            width: 25%;
            float: left;
            text-align: center;
            color: $color_text_grey;
            
            .views-field-field-usp-titel {
                font-weight: bold;
            }
            .views-field-field-usp-subtitel {
                padding: 0 50px;
            }
        }
    }
    .region-second-main {
        padding-top: 30px;
        
    }
}

@media all and (min-width: $breakpoints_mobile) {
    #header .view-home-header .views-field-field-startafbeelding {
        display: none !important;
    }
}
@media all and (max-width: $breakpoints_mobile) {
    #header .view-home-header .views-field-field-vimeo-video,
    #header .view-home-header .views-field-field-vimeo-video-hond,
    #header .view-home-header .views-field-field-vimeo-video-kat {
        display: none !important;
    }
    #header .view-home-header .views-field-field-startafbeelding {
        display: block !important;
    }
}