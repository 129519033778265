/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 28-sep-2016, 11:06:07
    Author     : keescornelisse1
*/

@mixin input() {
    height: 36px;
    line-height: 36px;
    box-sizing: border-box;
    border: 0 none;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    padding: 0 13px;
    outline:none;
    
    @include placeholder(#ffffff); 
}

.node-type-webform {
    .view-header-vacature {
        width: 100%;
        position: relative;
        background-color: $colors_grey_bg;
        
        .views-field-field-startafbeelding, .home-bg-image {
            width: 100%;
            height: 450px;
            position: relative;
            opacity: 1;
        }
    }

    h1.rtecenter {
      text-align: center;
    }
    
    .main-spanner .block-center {
        max-width: 100%;
    }
    .field-collection-container {
        margin-bottom: 0;
        border-bottom: 0 none;
    }
}
.node-type-webform.page-node-8099,
.node-type-webform.page-node-9285,
.node-type-webform.page-node-5022 {
    #block-system-main  .columns {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .column-left {
            @media all and (min-width: $default_max_width) {
                width: 225px;
                position: absolute;
                z-index: 9;
                transform: translateX(20px);

                .callout01 {
                    margin: 0 0 0;
                    width: 100% !important;
                }
            }

            // iframe {
            //     width: calc(100% + 50px);
            //     float: right;
            //     margin-top: 20px;
            //     height: 154px;
            // }

            h2 {
                text-align: center;
            }

            .callout01 {
                float: none;
                width: 100%;
                max-width: 550px;
                margin: 0 auto;
                
                h4 {
                    margin-bottom: 0;
                }
                // .left {
                //     width: 50%;
                //     float: left;
                // }
                // .right {
                //     width: 50%;
                //     float: left;
                //     box-sizing: border-box;
                // }
                ul {
                  font-weight: 600;
                  padding: 10px 20px;
                  display: block;
                  border-radius: 8px;
                  margin-bottom: 0;
                  background: #84a907;

                  li {
                    margin-left: 0;

                    &:before {
                        display: inline-block;
                        position: static;
                        vertical-align: middle;
                        margin-right: 5px;
                    }
                    // &:before {
                    //   content: none !important;
                    // }
                    &.phone:before {
                        background: transparent url('../images/careteam-telefoon.png') center center no-repeat;
                        background-size: contain;
                    }
                    &.contactform:before {
                      background: transparent url('../images/careteam-formulier.png') center center no-repeat;
                      background-size: contain;
                    }
                    &.chat:before {
                      background: transparent url('../images/careteam-chat.png') center center no-repeat;
                      background-size: contain;
                    }
                    &.facebook:before {
                      background: transparent url('../images/careteam-facebook.png') center center no-repeat;
                      background-size: contain;
                    }
                    &.whatsapp:before {
                      background: transparent url('../images/careteam-whatsapp.png') center center no-repeat;
                      background-size: contain;
                    }
                    
                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                  }
                }
            }
        }

        &.sticky {
            @media all and (min-width: $default_max_width) {
                .column-left {
                    position: fixed;
                    top: 70px;
                }
            }
        }
        &.sticky-end {
            @media all and (min-width: $default_max_width) {
                .column-left {
                    position: absolute;
                    top: auto;
                    bottom: 20px;
                }
            }
        }
        .column-right {
          width: 100%;
          
          @media all and (min-width: $default_max_width) {
            .field.field-name-field-blokken>.field-items>.field-item .field-collection-view {
              max-width: 1280px;
              box-sizing: border-box;
              padding-left: 250px;

              .view-mode-full {
                display: flex;
                // justify-content: center;
                // align-items: center;
              }
              
            }
          }
        }
        
    }
}
.node-type-webform {

  .view-careteam-header {
    @include video_header(400px, $colors_grey_bg);
    
    .views-field-field-startafbeelding.video-under-it {
      opacity: 1;

      &.hide {
        opacity: 0;
      }
    }

    .views-field-field-vimeo-video {
      &:before {
        content: "";
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        margin-top: -30px;
        left: 50%;
        margin-left: -30px;
        background: transparent url('../images/icon-play.png') center center no-repeat;
        cursor: pointer;
      }
    }
    &.fullscreen {
      .views-field-field-vimeo-video:before {
        content: none;
      }
      .views-field-field-vimeo-video {
        height: 80vh;

        video {
          margin-top: -40px !important;
        }
        @media all and (min-width: 1500px) {
          video {
            width: 1415px;
            margin: 0 auto;
            display: block;
            float: none;
          }
          background-color: #084157;
        }
      }
    }
  }

    #block-system-main .field {
        width: 100%;
        
        .field-items {
            max-width: $breakpoints_tablet;
            width: 100%;
            margin: 0 auto;
            position: relative;
        }

        &.field-name-field-youtube-video {
            .field-items {
                max-width: 100%;
            }
        }
        
        &.field-name-field-headertekst {
            background-color: $colors_grey_bg;
            padding: 50px 0;
            color: $colors_white;
            
            h1 {
                color: $colors_white;
                margin-bottom: 40px;
                font-size: 36px;
                line-height: 44px;
            }
            p {
                color: $colors_base;
                font-weight: bold;
                font-size: 20px;
                line-height: 34px;
                padding: 0 15px;
                box-sizing: border-box;
            }
        }
        &.field-name-field-link {
          background-color: #eeeeee;
          padding: 20px 0;
          text-align: center;

          a.cta {
            @include button_green();
            
            padding: 8px 30px;
            font-size: 20px;
            font-weight: bold;
          }
        }
        &.field-name-title {
            .field-items {
                max-width: 800px;
                margin-top: 40px;
            }
        }
        &.field-name-field-usp-s {
          background-color: $colors_grey_bg;
          padding: 20px 0 40px;
          overflow: hidden;

          .field-collection-view {
              border-bottom: 0 none;
          }

          &>.field-items>.field-item {
            width: 25%;
            float: left;
            text-align: center;
            color: $colors_white;

            @media all and (max-width: 1023px) {
                width: 50%;
            }

            img {
              max-width: 100px;
              height: auto;
            }
            .field-name-field-usp-titel {
              font-weight: bold;
              max-width: 155px;
              margin: 0 auto;
            }
            .field-name-field-usp-subtitel {
              padding: 0 20px;
              box-sizing: border-box;
            }
          }
        }
        
        &.field-name-body {
            box-sizing: border-box;
            @include body_style();
            margin-bottom: 40px;
        }
        &.field-name-field-blokken {
          padding-top: 0px;

          & > .field-items {
            max-width: 100%;
            width: 100%;

            &>.field-item {
              max-width: 100%;
              width: 100%;
              margin: 0 auto;
              position: relative;

              &:nth-child(even) {
                background-color: #eeeeee;
              }

              .field-collection-view {
                max-width: $breakpoints_tablet;
                width: 100%;
                margin: 0 auto;
                position: relative;
              }
            }
          }
          
          .field-collection-view {
            border-bottom: 0 none;
            padding: 40px 0;
            max-width: $default_max_width;
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
          }

          @media all and (max-width: calc(#{$default_max_width} + 40px)) {
            .field-item {
              padding-left: 20px;
              padding-right: 20px;
              box-sizing: border-box;

              &.even {
                .group-left {
                  padding-right: 20px;
                  padding-left: 0;
                  box-sizing: border-box;

                }
                .group-right {
                    padding-left: 30px;
                    
                    .field-item {
                        padding-left: 0;
                    }
                }
              }
            }
          }

          .field-item.odd {
            @media all and (min-width: 768px) {
              .field-collection-item-field-blokken {
                display: flex;

                .group-left {
                  order: 2;
                  padding-left: 20px;

                  img {
                    float: right;
                  }
                }

                .group-right {
                  order: 1;
                  padding-left: 0;
                  padding-right: 20px;
                }
              }
            }
          }

          .field-name-field-blok-titel {
            margin-bottom: 5px;

            h2 {
                font-size: 18px;
                margin-bottom: 0;
            }
          }

          .field-name-field-blok-afbeelding {
            img {
              max-width: 100%;
              height: auto;
            }
          }

          .field-name-field-blok-tekst {
              p {
                  // font-size: 14px;
                  margin-bottom: 10px;
              }
          }
          
          .field-item {
            margin-bottom: 0;

            .group-left {
                width: 50%;
            }
            .group-right {
                width: 50%;
            }

            .group-right {
              padding-left: 40px;
              box-sizing: border-box;
            }
          }

          @media all and (max-width: 767px) {
            .field-collection-item-field-blokken {
              display: block;

              .group-left,
              .group-right {
                width: 100%;
                padding-right: 0;

                .field-item {
                  padding-left: 0;
                  padding-right: 0;
                }

                img {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
    }
    .second-main {
        .block {
            max-width: 100%;
            
        }
    }
    
    .node .content ul.menu {
        background-color: $colors_white;
        border-right: 0 none;
        max-width: 100%;
        padding: 20px auto 0;
        display: block;
        height: 43px;
        text-align: center;

        li {
            border-right: 0 none;
            display: inline-block;
            float: none;
            background-color: $colors_white;
            color: $colors_base;
            font-size: 18px;
            font-weight: normal;
            padding: 7px 25px !important;
            border: 0 none;
            margin-top: 1px;
            margin-left: 0;

            &.active-trail, &:hover {
                background-color: $colors_grey_bg_light;
                margin-bottom: 0 !important;
                color: $colors_white;
                
                a {
                    color: #ffffff;
                }
            }
            a {
                color: $colors_base;
                text-decoration: none;
            }
        }
    }
    
}

.block-webform {
    background-color: $colors_grey_bg_light;
    padding: 40px 0 40px;
    max-width: 100% !important;
    
    h2 {
        color: #ffffff;
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
    }
    
    .webform-client-form {
        
        
        & > div {
            max-width: 820px;
            
            .form-actions {
                input {
                    margin-left: 20%;
                }
            }
        }
    }
}

.webform-client-form {
    background-color: $colors_grey_bg_light;
    // border-top: 1px solid #ccc;
    padding: 80px 0 40px;

    &.webform-client-form-5022 {
      .form-item {
        &.webform-component--postcode,
        // &.webform-component--telefoonnummer,
        &.webform-component--naam-huisdier,
        &.webform-component--ras-huisdier,
        &.webform-component--adres {
          display: none;
        }
      }
    }
    
    & > div {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        position: relative;
        
        fieldset {
            border: 0 none;
            margin: 0 0 0 0;
            padding: 0 0 10px 0;
            
            legend {
                font-weight: bold;
                margin: 0 auto 15px;
                max-width: 980px;
                width: 100%;
            }
        }
        
        .form-item {
            color: $colors_white;
            max-width: 980px;
            margin: 0 auto 20px;
            position: relative;
            overflow: hidden;

            // &.webform-component--postcode,
            // &.webform-component--telefoonnummer,
            &.webform-component--naam-huisdier,
            &.webform-component--ras-huisdier {
              display: none;
            }

            h2 {
                text-align: center;
            }
            
            label {
                width: 20%;
                display: block;
                float: left;
            }
            input, select, textarea {
                @include input();

                &[name="emailaddress"] {
                    width: 300px;
                    background-color: $color_green_input;
                    color: $colors_white;
                    margin-right: 10px;
                    -moz-appearance:    textfield;
                    -webkit-appearance: textfield;
                    appearance: textfield;
                }
                
                &[type="file"] {
                    height: 21px !important;
                    line-height: 19px !important;
                    padding-left: 0;
                    border-radius: 0 !important;
                    margin-top: 5px;
                }
                
            }
            &.webform-component-textarea {
                label {
                    float: left;
                }
            }
            .form-textarea-wrapper {
                width: 529px;
                float: left;
                
                textarea {
                    width: 529px;
                    height: 150px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    resize: none;
                }
                
                .grippie {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
            .MultiFile-list {
                margin-left: 230px;
                
                & > .MultiFile-label {
                    & > span {
                        float: left;
                        
                        .MultiFile-label {
                            margin-bottom: 10px;
                        }
                    } 
                }
            }
            .MultiFile-list .MultiFile-label {
                width: 100%;
                display: block;
                overflow: hidden;
                font-size: 0;
                
                .MultiFile-remove {
                    float: right;
                    display: inline-block;
                    font-size: 16px;
                }
                .MultiFile-preview {
                    float: left;
                    margin-right: 10px;
                }
                .MultiFile-title {
                    font-size: 16px;
                }
/*                    & > span {
                    float: left;
                    
                    
                }*/
            }
        }
        .form-actions {
            max-width: 980px;
            margin: 0 auto 20px;
            position: relative;
            
            input {
                @include input();
                display: inline-block;
                background-color: $colors_green;
                color: $colors_white;
                font-weight: bold;
                cursor: pointer;
                transition: all 0.3s;
                margin-left: 200px;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

.page-node-5022 .page #block-system-main .field_youtube_video, 
.page-node-5022 .page #block-system-main .field.field-name-field-youtube-video {
    overflow: hidden;
    max-height: 50vh;

    &.playing {
        height: 70vh;
        max-height: 100%;

        .field-item {
            margin-top: -95px;
            margin-bottom: 0px;
        }
    }

    .field-item {
        margin-top: -150px;
        margin-bottom: -80px;
    }
}

.page-node-5022 .page #block-system-main .field.field-name-field-headertekst {
    position: relative;
    padding: 0 0 0 0;
    background-color: transparent;
    background-image: none;

    .field-item {
        position: absolute;
        bottom: 20px;
        z-index: 9;
        width: 100%;
    }
}