/*
 Ingeschoten door EF2 B.V. - www.ef2.nl
 Doeltreffende communicatie
 Prima samenspel van Kees, Simon, Arthur en Diederick
 Hoewel wij de makers zijn, rusten de rechten bij onze klant.
*/
/* 
    Created on : 28-sep-2016, 11:06:07
    Author     : keescornelisse1
*/

.node-type-nieuws {
    .view-header-vacature {
        width: 100%;
        height: 450px;
        position: relative;
        background-color: $colors_grey_bg;
        
        .views-field-field-startafbeelding, .home-bg-image {
            width: 100%;
            height: 450px;
            position: relative;
            opacity: 1;
        }
    }
    #main {
        width: 100%;
        max-width: $default_max_width;
        margin: 0 auto;
        position: relative;
        
        .main-content {
            
            h1, #block-system-main {
                width: 67%;
                float: left;
            }
            #block-views-alle-nieuws-block {
                float: left;
                width: 33%;
                margin-top: -35px;
                box-sizing: border-box;
                padding-left: 40px;
                
                h2 {
                    color: $color_text_grey;
                    font-size: 36px;
                    margin-bottom: 20px;
                }
                .view-content .views-row {
                    font-size: 24px;
                    padding: 10px 0 20px;
                    border-bottom: 3px solid $colors_grey_light;
                    margin-bottom: 10px;
                    
                    a {
                        color: $color_text_grey;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .second-main .block-center {
        width: 100%;
        max-width: $default_max_width;
        margin: 0 auto;
        position: relative;
    }
    .region-second-main .nieuws-footer {
        width: 67%;
        float: left;
        
        .at-share-btn-elements {
            text-align: right;
            
            .at-share-btn {
                background-color: #97c00e !important;
            }
        } 
        .block-center {
            border-top: 3px solid $colors_grey_light;
            padding-top: 40px;
            margin-top: 20px;
        }
        
        .content {
            padding: 50px 0;
            font-size: 20px;

            p {
                margin: 0 auto;
                margin-bottom: 50px;
                line-height: 30px;
                text-align: center;

                &:nth-child(1) {
                    color: $color_text_grey;
                }
                &:nth-child(2) {
                    padding: 0 30px;
                    box-sizing: border-box;
                }
            }
            .auteur_team_contact {
                list-style: none;
                margin: 20px auto 60px;
                background-color: $colors_grey_bg;
                height: 80px;
                line-height: 80px;
                text-align: center;
                color: $colors_white;

                li {
                    display: inline-block;
                    padding: 0 30px;
                    
                }
            }
        }
    }
    
    h1 {
        color: $color_text_grey;
        margin: 40px 0 0;
        font-size: 36px;
        line-height: 44px;
    }
    
    .main-spanner .block-center {
        max-width: 100%;
    }
    #block-system-main .field {
        width: 100%;
        
        .field-items {
            max-width: $breakpoints_tablet;
            width: 100%;
            margin: 0 auto;
            position: relative;
        }
        &.field-name-field-subtitel {
            color: $colors_base;
            font-weight: normal;
            font-size: 30px;
            line-height: 40px;
            box-sizing: border-box;
            padding-right: 130px;
            margin-top: 20px;
        }
        &.field-name-field-headertekst {
            background-color: $colors_grey_bg;
            padding: 50px 0;
            color: $colors_white;
            
            p {
                color: $colors_base;
                font-weight: bold;
                font-size: 20px;
                line-height: 34px;
                padding: 0 15px;
                box-sizing: border-box;
            }
        }
        &.field-name-body {
            box-sizing: border-box;
            @include body_style();
            margin-bottom: 40px;
        }
    }
    .second-main {
        .block {
            max-width: 100%;
            
        }
    }
    #block-views-alle-vacatures-block {
        background-color: $colors_green;
        color: $colors_white;
        padding: 40px 0; 
        overflow: hidden;
        
        h2, .view-content {
            max-width: 980px;
            width: $default_width;
            margin: 0 auto;
            position: relative;
        }
        h2 {
            margin-bottom: 30px;
            font-size: 34px;
        }
        .view-content {
            border-top: 2px solid rgba(255,255,255, 0.5);
        }
        .views-row {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            border-bottom: 2px solid rgba(255,255,255, 0.5);
            cursor: pointer;
            
            .views-field-title {
                float: left;
                font-weight: bold;
            }
            .views-field-view-node {
                float: right;
                font-size: 16px;
                a {
                    transition: all 0.3s;
                }
            }
            
            a {
                color: $colors_white;
                text-decoration: none;
            }
            &:hover {
                .views-field-view-node {
                    line-height: 37px;
                    
                    a {
                        @include button_red();
                        height: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}